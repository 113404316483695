.line-tab {
    border: 2px solid $primary10;
    top: 34px;
    width: 100%;
    box-sizing: border-box;
}

.line-tab-option {
    border: 3px solid $primary;
  z-index: 10000;
}

