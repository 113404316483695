.default-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
}

.select-lang {
    width: 66px;
    height: 48px;
}

.select-drop-down-not-logged {
    .select-options {
        font-size: 20px !important;
        font-weight: 500 !important;
        line-height: 24px;
    }
}

.walet-btn {
    width: 50px;
    height: 48px;
    border: 1px solid $primary70 !important;
}

.panier-btn {
    height: 48px;
    padding: 8px;
    color: $primary70 !important;
    min-width: 48px;
}

.input-search {
    width: 100%;

    .default-select {
        height: 48px;
        padding: 0px 50px !important;
    }
}

.select-lang {
    width: 66px;
    height: 48px;
    padding-left: 3px !important;
}

.container-option-drop {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    max-height: 110px;
    overflow-y: auto;

    input {
        zoom: 60%;
    }
}

.walet-btn {
    width: 50px;
    height: 48px;
    border: 1px solid $primary70 !important;
}

.align-self {
    text-align: start;
}

.align-end {
    text-align: end;
}

.search-options {
    padding: 24px 32px 16px 24px;
    width: calc(50% - 153px) !important;
    height: auto !important;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    max-height: 574px !important;
    position: absolute;
}

.search-options-menu {
    padding: 24px 32px 16px 24px;
    width: 100% !important;
    height: auto !important;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    max-height: 574px !important;
    position: absolute;
}

.search-menu {
    padding: 24px 32px 16px 24px;
    height: auto !important;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    max-height: 574px !important;
    position: absolute;
}

.search-menu-not-logged {
    padding: 24px 32px 16px 24px;
    height: auto !important;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    max-height: 574px !important;
    position: absolute;
}

.search-menu-logged {
    padding: 24px 32px 16px 24px;
    height: auto !important;
    z-index: 100000;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    max-height: 574px !important;
    position: absolute;
}


.distance {
    display: flex;
    align-items: end;
    align-content: end;
    justify-content: end;
}

.padding-navbar {
    padding: 23px 40px;
}

.login-btn {
    height: 48px;
    width: 100%;
    min-width: 173px;
    padding: 14px 24px;
    border-radius: 16px;
}

.commercant-btn {
    height: 48px;
    width: 100%;
    min-width: 229px;
    padding: 14px 24px;
    border-radius: 16px;
}

.button-scroll {
    top: 0;
    bottom: 0;
}

.filter-menu {
    width: 520px;
    height: 48px !important;
}

.drop-down-location {
    width: 50%;

    .ds-ml-15 {
        margin-left: 0 !important;
    }

    .ds-mb-6 {
        margin-bottom: 0px !important;
    }
}

.gap-navbar-20 {
    gap: 20px;
}

.height-location {
    width: calc(100% + 250px);
    height: 48px;
    border-radius: 16px;
}

.ds-pl-44 {
    padding-left: 48px !important;
}

.height-search {
    width: calc(100% - 50px);
    height: 48px !important;
    border-radius: 16px;
    padding: 0px 50px;
}

.ds-input-select::placeholder {
    color: $primary20;
}

.default-input,
input::placeholder {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.navbar-element {
    width: 100%;
}

.search-width {
    width: 100%;
}

.align-content {
    justify-content: flex-end;
    align-content: flex-end;
    display: flex;
}

.delivery-address {
    .search-icon {
        top: 5px !important;
        color: $primary70;
    }
}

.menu-filter {
    .ds-px-8 {
        padding-left: 16px !important;
    }
}

.select-drop-down {
    .select-options {
        font-size: 20px !important;
        font-weight: 500 !important;
        line-height: 24px;
    }
}

.compose-form-class,
.my-sites-class {
    .horizontal-line {
        margin: 0px !important;
    }
}

.commande-class .tab-command {
    padding-top: 27px !important;

    .horizontal-line {
        margin: 0px !important;
    }
}

.cmd-class {
    .horizontal-cmd-line {
        margin: 22px 0 0 0;
    }
}

.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    z-index: 1;
    width: 100%;
}

//lg
@media (max-width:992px) {
    .cmd-class {
        .horizontal-cmd-line {
            margin: 16px 0;
        }
    }

    .height-100 {
        height: auto !important;
    }

    .commande-class {
        padding: 16px !important;

        .tab-command {
            padding-top: 27px !important;
        }
    }

    .search-options {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 670px !important;
        position: absolute;
    }

    .search-options-menu {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 670px !important;
        position: absolute;
        width: 100%  !important;
    }

    .commercant-btn {
        height: 32px;
        width: auto;
        padding: 11px 8px;
        border-radius: 6px;
    }

    .login-btn {
        height: 32px;
        min-width: 128px;
        padding: 11px 8px;
        border-radius: 6px;
        width: auto;
    }

    .category-name {
        padding-left: 8px;
    }

    .drop-down-location .search-icon {
        top: 2px !important;
    }

    .search-icon {
        top: 5px !important;
    }

    .padding-navbar {
        padding: 16px;
    }
    .ds-pt-94{
        padding-top: 64px;
    }
    .gap-navbar-20 {
        gap: 10px !important;

    }

    .height-location,
    .height-search,
    .walet-btn {
        width: 32px;
        height: 32px !important;
        min-height: 32px !important;
        border-radius: 11px;

        .ds-px-8 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        padding: 0px !important;

        .ds-top-7 {
            top: 2px;
            right: -11px !important;
            left: 5px !important;
        }
    }

    .walet-btn {
        svg {
            height: 21px !important;
            width: 21px !important;
            color: $primary70;
        }
    }

    .panier-btn {
        height: 32px !important;
        min-height: 32px !important;
        border-radius: 11px;
        padding: 5px;
        min-width: 32px;

        svg {
            height: 21px !important;
            width: 21px !important;
        }
    }

    .navbar-element {
        width: auto;
        gap: 10px;
    }

    .drop-down-location {
        .ds-wp-20 {
            width: 16px !important;
        }

        .ds-hp-20 {
            height: 16px !important;
        }

        .ds-top-7 {
            top: 2px;
            right: -11px !important;
            left: 4px !important;
        }
    }

    .height-search {
        .ds-px-12 {
            padding: 0 !important;
        }
    }

    .search-width {
        .ds-top-7 {
            top: 2px !important;
            left: 6px !important;
        }
    }

    .search-width-not-logged {
        .ds-top-7 {
            top: 3px !important;
            left: 3px !important;
        }
    }

    .input-simple {
        padding: 0px 42px;

        .ds-top-7 {
            top: 2px;
            right: -11px !important;
            left: 6px !important;
        }
    }

    .grow-1 {
        flex-grow: 1;
    }

    .select-lang {
        width: 32px;
        height: 32px !important;
        min-height: 32px !important;
        border-radius: 11px;

        svg {
            visibility: hidden;
        }

        .ds-px-8 {
            padding-left: 4px !important;
            padding-right: 0 !important;
        }

        .ds-top-7 {
            top: 2px !important;
            right: 0px !important;
            left: 19px !important;
        }

        .ds-pl-36,
        .ds-pr-36 {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .input-search {
        width: 100% !important;

        .ds-top-7 {
            top: 10px;
            right: 11px !important;
        }
    }

    .input-search-responsive {
        margin-top: 30px;
        width: 100% !important;

        .search-icon {
            top: 10px !important;
        }

        .placeholder-class {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: block;
        }

        .ds-top-7 {
            top: 10px;
        }
    }

    .input-search .ds-text-size-12 {
        font-size: 10px;
    }

    .ds-px-40 {
        padding: 0 16px;
    }

    .menu-delivery {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 16px !important;

        .parimary-70-btn {
            height: 40px !important;
            border-color: $primary70 !important;
            border: 1px solid $primary70 !important;
            color: $primary70 !important;
            margin-bottom: 10px;
        }

        .delivery-mode .ds-switch input {
            appearance: none;
            height: 40px !important;
            width: 280px !important;
            border-radius: 12px;
            background-color: $primary10;
            border: 1px solid $primary10;
            outline: none;
        }

        .ds-switch input:checked+label {
            left: 141px;
            background: $white;
            transition: left 0.4s right 0.4s 0.4s;
            width: 137px;
            height: 37px;
            border-radius: 12px;
            top: 1.5px;
        }

        .delivery-mode .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            width: 137px;
            height: 37px;
            background: $white;
            border-radius: 12px;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            top: 1.5px;
        }

        .ds-switch input::before {
            right: -147px;
            top: 2px;
        }

        .ds-switch input::after {
            top: 2px;
        }

        .text-223 {
            position: absolute;
            right: 48px;
            top: 17px;
        }

        .text-224 {
            position: absolute;
            left: 49px;
            top: 17px;
        }
    }

    .planify-checkbox {
        padding-bottom: 0px !important;
    }

    .select-drop-down {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .menu-filter {
        height: 40px !important;
        min-height: 40px !important;
        width: 100%;
    }

    .menu-search-navbar {
        width: calc(100% - 125px);
        gap: 10px;
    }

    .info-site {
        margin: 0 !important;
        margin-top: 35px !important;
    }

    .bassket-class {
        .horizontal-line {
            margin-top: 16px !important;
        }
    }

    .horizontal-line {
        margin: 0px !important;
    }

    .button-cmd {
        margin-top: 10px;
    }


}

@media ((min-width: 321px) and (max-width: 900px)) {
    .compose-form-class {
        .horizontal-line {
            margin: 0px !important;
        }
    }
}

@media (max-width: 320px) {
    .select-drop-down {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .compose-form-class {
        .horizontal-line {
            margin: 0px !important;
        }
    }
}

.width-tab {
    width: calc(100vw - 500px);
}


.padding-multi-select {
    padding-left: 12px;
    padding-right: 12px;
}

@media(max-width:360px) {
    .search-menu-not-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;


        img {
            display: none;
        }
    }

    .select-drop-down-not-logged {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .menu-delivery {
        .delivery-mode {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: 250px !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: 129px;
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }
        }

        .ds-switch input:checked+label {
            left: 124px;
            background: #FFFFFF;
            transition: left 0.4s right 0.4s 0.4s;
            width: 125px;
            height: 37px;
            border-radius: 12px;
        }

        .text-223 {
            position: absolute;
            right: 48px;
            top: 20px;
        }

        .label-before {
            position: absolute !important;
            top: 4px !important;
            left: 154px !important;
            white-space: nowrap !important;
            z-index: 0 !important;
        }

        .label-after {
            position: absolute !important;
            top: 4px !important;
            z-index: 0 !important;
            width: 125px;
        }

        .tab-category {
            padding: 8px 0px !important;
        }

    }
}

@media (max-width: 768px) {
    .placeholder-class {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }

    .width-tab {
        width: 100%;
    }

    .next-category {
        display: none;
    }

    .default-basket {
        width: 100% !important;
        padding: 16px;
    }

    .ds-text-size-14 {
        font-size: 14px !important;
    }

    .custom-vocation {
        font-size: 12px !important;
        line-height: 14px !important;
        font-weight: 400 !important;
    }

    .custom-address {
        font-size: 12px !important;
        line-height: 14px !important;
        font-weight: 400 !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 130px;
    }

    .custom-site {
        font-size: 16px !important;
        line-height: 19px !important;
    }

    .custom-distance {
        font-size: 14px !important;
        line-height: 17px !important;
        font-weight: 400 !important;
        white-space: nowrap;
    }

    .custom-list-search-options {
        img {
            width: 76px !important;
            height: 62px !important;
        }

        .horizontal-line {
            margin: 12px 0 !important;
        }
    }

    .custom-menu-categories {
        img {
            width: 76px !important;
            height: 62px !important;
        }

        .horizontal-line {
            margin: 12px 0 !important;
        }
    }

    .label-before {
        position: absolute !important;
        top: 4px !important;
        left: 182px !important;
        white-space: nowrap !important;
        z-index: 0 !important;
    }

    .label-after {
        position: absolute !important;
        top: 4px !important;
        z-index: 0 !important;
        width: 125px;
    }

    .menu-delivery .text-223,
    .menu-delivery .text-224 {
        top: 20px;
    }

    .product-price {
        .ds-text-size-12 {
            font-size: 10px !important;
        }

        .ds-text-size-14 {
            font-size: 12px !important;
        }
    }

    .padding-multi-select {
        padding-left: 7px;
    }

    .search-options {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 670px !important;
        position: absolute;
    }
}

@media (min-width: 2000px) {
    .default-basket {
        width: 100% !important;
    }
}

@media (min-width: 1900px) {
    .menu-filter {

        .search-options {
            padding: 0px;
            height: auto !important;
            z-index: 100000;
            background: #FFFFFF;
            box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
            border-radius: 8px;
            max-height: 670px !important;
            position: absolute;
        }
        .input-search {
            .search-options {
                padding: 24px 32px 16px 24px;
                height: auto !important;
                z-index: 100000;
                background: #FFFFFF;
                box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
                border-radius: 8px;
                max-height: 574px !important;
                position: absolute;
            }
        }

    }


    .search-menu {
        padding: 24px 32px 16px 24px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }

    .height-40 {
        height: calc(100vh - 292px);
    }

    .search-menu-logged {
        padding: 24px 32px 16px 24px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

.ds-overflow-xy,
.ds-overflow-x,
.ds-overflow-y {
    scrollbar-width: none;
    scrollbar-color: $transparent;
}

@media (min-width: 1251px) and (max-width: 1700px) {
    .search-menu-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media (min-width: 1201px) and (max-width: 1250px) {
    .search-menu-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .search-menu-not-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;

    }

    .search-menu-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media(min-width:1051px) and (max-width:1100px) {
    .search-menu-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media(min-width:1025px) and (max-width:1050px) {
    .search-menu-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media (max-width:1172px) {
    .search-menu {
        padding: 24px 32px 16px 24px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media (max-width:1197px) {
    .search-menu {
        padding: 24px 32px 16px 24px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }

}


@media (max-width:1200px) and (min-width:985px) {
    .height-location {
        width: inherit;
    }
}

@media (max-width:1024px) {
    .height-location {
        width: inherit;
    }

    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    }
}

@media (max-width:1024px) {
    .search-menu-not-logged {
        padding: 16px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;

    }
}

@media (max-width:992px) {
    .search-menu-not-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;

    }

    .shadoww {
        .horizontal-line {
            margin: 0px !important;
        }
    }

    .horizontal-line {
        margin: 16px 0px !important;
    }


    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
 
    }
}

@media (max-width:768px) {
    .search-menu-not-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;


    }

    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
     
    }
}

@media (max-width : 576px) {
    .search-menu-not-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
        img {
            display: none;
        }
    }

    .height-location {
        width: inherit;
    }

    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
    
    }
}

@media (max-width: 498px) {
    .search-menu-not-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
        img {
            display: none;
        }
    }

    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;

        img {
            display: none;
        }
    }

    .menu-delivery {
        .delivery-mode {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: 280px !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 1px;
                right: 0px;
                width: 140px;
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px;
            }

            .ds-switch input:checked+label {
                left: 138px;
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: 140px;
                height: 37px;
                border-radius: 12px;
                top: 1.5px;
            }

            .text-223 {
                position: absolute;
                right: 49px;
                top: 20px;
            }

            .label-before {
                position: absolute !important;
                top: 4px !important;
                left: 180px !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .label-after {
                position: absolute !important;
                top: 4px !important;
                width: 125px;
                z-index: 0 !important;
            }
        }
    }
    .compose-form-class {
        .horizontal-line {
            margin: 0px !important;
        }
    }

}

@media (max-width:400px) {
    .search-menu-not-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
        img {
            display: none;
        }
    }

    .search-menu-logged {
        padding: 12px;
        height: auto !important;
        z-index: 100000;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
        border-radius: 8px;
        max-height: 574px !important;
        position: absolute;
        img {
            display: none;
        }
    }
}