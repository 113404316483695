.container-modal {
    background-color: $greyBackground;
    opacity: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
}

.content-modal {
    z-index: 100001;
    min-height: 150px;
    overflow: auto;
}

.content-padding {
    overflow: hidden;
}


.subscription-modal {
    width: 500px !important;
    height: 612px !important;
    min-width: 300px !important;
    min-height: 300px !important;
}

.confirmation-code-modal {
    width: 500px;
    height: 492px;
    min-width: 300px;
    min-height: 300px;
}

.final-subscription-modal {
    width: 500px;
    height: 690px;
    min-width: 300px;
    min-height: 300px;
}

.content-password {
    text-align: right;
    text-decoration-line: underline;
    align-self: stretch;
}

.modal-connexion {
    width: 500px;
    height: 612px;
    min-width: 300px !important;
    min-height: 300px !important;

    .ds-p-40 {
        padding: 30px;
    }
}

.modal-forget-password {
    width: 500px;
    height: 412px;
    min-width: 300px !important;
    min-height: 300px !important;
}

.modal-reset-password {
    width: 500px;
    height: 551px;
    min-width: 300px !important;
    min-height: 300px !important;
}

.ds-border-primary30 {
    border: 1px solid $primary30;
}

.content-social {
    padding: 12px 50px;
}

.ds-text-center {
    text-align: center;
}

.continue-text {
    font-size: 12px;
    width: 100%;
}

.modal-login {
    width: 500px;
    height: 481px;
    min-width: 300px !important;
    min-height: 300px !important;
}

.modal-Delivery-data {
    width: 395px;
    height: 717px;
    min-width: 300px !important;
    min-height: 300px !important;

    .label-before {
        position: absolute;
        top: 6px;
        left: 198px;
        white-space: nowrap;
        z-index: 0;
    }

    .label-after {
        position: absolute;
        top: 9px !important;
        z-index: 0;
        width: 160px;
    }

    .ds-switch input {
        appearance: none;
        height: 40px !important;
        width: 323px !important;
        border-radius: 12px;
        background-color: #E6EAEB;
        border: 1px solid #E6EAEB;
        outline: none;
    }

    .ds-switch input:not(:checked)+label {
        left: 2px;
        right: 0px;
        width: 161px;
        height: 37px;
        background: #FFFFFF;
        border-radius: 12px;
        transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        top: 1.5px !important;
    }

    .ds-switch input:checked+label {
        left: 159px;
        background: #FFFFFF;
        transition: left 0.4s right 0.4s 0.4s;
        width: 161px;
        height: 37px;
        border-radius: 12px;
        top: 1.5px !important;
    }

    .label-before {
        position: absolute !important;
        top: 9px !important;
        left: 200px !important;
        white-space: nowrap !important;
        z-index: 0 !important;
    }

    .text-223 {
        position: absolute;
        right: -54px;
        top: 20px;
    }

}

.modal-Delivery-order {
    width: 395px;
    height: 656px;
    min-width: 300px !important;
    min-height: 300px !important;

    .label-before {
        position: absolute;
        top: 6px;
        left: 198px;
        white-space: nowrap;
        z-index: 0;
    }

    .label-after {
        position: absolute;
        top: 9px !important;
        z-index: 0;
        width: 125px;
    }

    .ds-switch input {
        appearance: none;
        height: 40px !important;
        width: 323px !important;
        border-radius: 12px;
        background-color: #E6EAEB;
        border: 1px solid #E6EAEB;
        outline: none;
    }

    .ds-switch input:not(:checked)+label {
        left: 2px;
        right: 0px;
        width: 161px;
        height: 37px;
        background: #FFFFFF;
        border-radius: 12px;
        transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        top: 1.5px !important;
    }

    .ds-switch input:checked+label {
        left: 159px;
        background: #FFFFFF;
        transition: left 0.4s right 0.4s 0.4s;
        width: 161px;
        height: 37px;
        border-radius: 12px;
        top: 1.5px !important;
    }

    .label-before {
        position: absolute !important;
        top: 9px !important;
        left: 200px !important;
        white-space: nowrap !important;
        z-index: 0 !important;
    }

    .text-223 {
        position: absolute;
        right: -54px;
        top: 20px;
    }

}

.become-retailer-bg {
    width: 780px;
    right: 0;
}

.become-retailer-content {
    width: 780px;
    left: 0;
}

@media (max-width:1400px) {

    .become-retailer-bg,
    .become-retailer-content {
        width: 600px;
    }
}

@media (min-width:1024px) {
    .modal-compose-title-text{
        font-size: 28px !important;
        font-weight: bold !important;
        line-height: 33.6px !important;
    }

    .modal-compose-price-text{
        font-size: 20px !important;
        font-weight: bold !important;
        line-height: 24px !important;
    }

    .modal-compose-discount-price-text{
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 19.2px !important;
    }

    .modal-compose-description-text{
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 19.2px !important;
        
    }

    .modal-compose-composer-formule-text{
        font-size: 24px !important;
        font-weight: 400 !important;
        line-height: 28.8px !important;
    }

    .modal-compose-subtitle-text{
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.8px !important;
    }

    .comment-input-mt-6{
        margin-top: 6px !important;
    }

    .modal-compose-tab-title-text{
        font-size: 20px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
    }

    .modal-compose-tab-title-text-selected{
        font-weight: bold !important;
    }

    .modal-compose-button-text{
        font-size: 16px !important;
        font-weight: bold !important;
        line-height: 19.2px !important;
    }
}
@media (max-width:1024px) {
    .modal-compose-title-text{
        font-size: 20px !important;
        font-weight: bold !important;
        line-height: 24px !important;
    }

    .comment-input-mt-6{
        margin-top: 6px !important;
    }

    .modal-compose-tab-title-text{
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.8px !important;
    }
    .modal-compose-tab-title-text-selected{
        font-weight: bold !important;
    }

    .modal-compose-discount-price-text{
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 14.4px !important;
    }

    .modal-compose-price-text{
        font-size: 16px !important;
        font-weight: bold !important;
        line-height: 19.2px !important;
    }

    .modal-compose-subtitle-text,
    .modal-compose-description-text{
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16.8px !important;
        
    }

    .modal-compose-composer-formule-text,
    .modal-compose-button-text{
        font-size: 16px !important;
        font-weight: bold !important;
        line-height: 19.2px !important;
    }
}




@media (max-width:1240px) {

    .become-retailer-bg,
    .become-retailer-content {
        width: 500px;
    }
}

@media (max-width:1140px) {

    .become-retailer-bg,
    .become-retailer-content {
        width: 380px;
    }
}

@media (max-width:1024px) {

    .become-retailer-bg,
    .become-retailer-content {
        width: 300px;
    }
}

.modal-Button {
    background-color: $primary10 !important;
    border: none;
}

.modal-Button-2 {
    background-color: $primary20;
    border: none;
    color: $white;
}

.modal-Button-2:focus {
    color: $white !important;
}

.modal-add-note {
    width: 500px;
    height: 450px;
    min-width: 300px !important;
    min-height: 300px !important;
    overflow: hidden !important;

    .overflow-hidden {
        overflow: hidden !important;
    }
}

.modal-coupon {
    width: 413px;
    height: 378px;
    min-width: 300px !important;
    min-height: 300px !important;
}

.modal-paiment {
    width: 410px;
    height: 648px;
    min-width: 300px !important;
    min-height: 300px !important;
}

.default-input::placeholder {
    color: $primary20 !important;
    font-size: 14px !important;
    opacity: 1;
}

.shadow {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.absolute-img {
    right: 12px;
    top: 40px;
}

.vector-img {
    top: 20px;
    right: 6px;
}

.display-none {
    visibility: hidden;
}

.display-block {
    visibility: visible;
}

.contact-us-modal {
    width: 500px !important;
    max-height: 745px !important;
    min-width: 300px !important;
    min-height: 300px !important;
    overflow-x: auto !important;
}

.background-primary10 {
    background-color: $primary10 !important;
}

.site-modal {
    width: 500px;
    height: 646px;
    min-width: 300px !important;
    min-height: 300px !important;
    padding: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;

    img {
        width: inherit;
        height: 206px;
        border-radius: 14px 14px 0px 0px;
    }

    .ds-p-10 {
        padding: 0 !important;
    }

    .ds-pr-20 {
        padding-right: 0 !important;
    }

    .ds-pb-20 {
        padding-bottom: 0 !important;
    }
}

.site-modal-horaire {
    width: 500px;
    height: 928px;
    min-width: 500px !important;
    max-width: 500px !important;
    min-height: 646px !important;
    max-height: 928px !important;
    padding: 0 !important;

    img {
        width: inherit;
        height: 206px;
        border-radius: 14px 14px 0px 0px;
    }

    .ds-p-10 {
        padding: 0 !important;
    }

    .ds-pr-20 {
        padding-right: 0 !important;
    }

    .ds-pb-20 {
        padding-bottom: 0 !important;
    }
}

.absolute-close-modale {
    right: 0;
    border-radius: 50%;
}

.border-dashed {
    border: 1px dashed $primary10;
}

.dot-primary100 {
    height: 5px;
    width: 5px;
    background-color: $primary100;
    border-radius: 50%;
    display: inline-block;
}

.product-details-modal {
    width: 1056px;
    height: 580px;
    min-width: 300px;
    min-height: 300px;

    img {
        width: 492px;
        height: 492px;
    }

    .ds-switch {
        height: 48px;
        width: 100px;
        border-radius: 16px;
    }

    .ds-switch input {
        height: 48px;
        width: 178px;
        border-radius: 16px;
    }

    .ds-switch input::after {
        content: "Gramme";
        left: 46px;
        top: 10px;
    }

    .ds-switch input::before {
        content: "kilo";
        right: -62px;
        top: 10px;
    }

    .ds-switch input:checked+label {
        left: 90px;
        right: -76px;
        top: 1.5px;
        bottom: 0px;
        border-radius: 14px;
        width: 86px;
        height: 44px;
    }

    .ds-switch input:not(:checked)+label {
        left: 1px;
        right: 0px;
        width: 86px;
        height: 44px;
        border-radius: 14px;
    }

    .quatity-input {
        height: 48px;
        width: 72px;

        input {
            text-align: center;
        }
    }

    .quantity-btn {
        height: 48px !important;
        width: 48px !important;
    }

    .width-500 {
        width: 448px !important;
    }
}

.details-product {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 24px;
}

.tab-class::-webkit-scrollbar,
.tab-class::-webkit-scrollbar-thumb,
.tab-class::-webkit-scrollbar-track {
    display: none;
}

.modal-compose-formule {
    width: 1056px;
    height: 804px;
    min-width: 1056px;
    min-height: 804px;

    *::-webkit-scrollbar {
        width: 3px !important;
        height: 2px !important;
        border-radius: 0px !important;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $primary10 !important;
        border-radius: 2px;
        border: 1px solid $primary10 !important;
    }

    img {
        width: 387px;
        height: 280px;
    }
}

.input-note {
    width: 387px;
}

.description-width {
    width: 340px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.max-list-height {
    max-height: 87%;
}

.modal-compose-formule {
    .ds-p-32 {
        padding: 20px 20px 0 20px !important;
    }
}

.message-send-modal {
    width: 500px !important;
    height: 286px !important;
    min-width: 300px !important;
    min-height: 286px !important;

    .ds-checkbox:checked {
        background: $white !important;
        border: 1px solid $success100 !important;
        border-radius: 3px;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $success100;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
}

.btn-upload {
    border: 1px solid $primary100;
    height: 24px;
    border-radius: 6px;
}

.modal-Bon-plan {
    width: 1056px;
    height: 704px;
    min-width: 300px;
    min-height: 704px;

    .product-card-bon-plan {
        height: 270px !important;
        width: calc(100vw / 4 - 161px) !important;

        .default-card.type3 {
            width: 264px;
            height: 306px;

            img {
                width: 245px;
                height: 147px;
            }

            .free,
            .availibility {
                width: 73px;
                height: 27.67px;
            }

            gap: 5px;

            .ds-p-16 {
                padding: 8px !important;
            }
        }
    }

}

.grid-times {
    display: grid;
    grid-template-columns: 50% 25% 25%
}

.top-55 {
    top: 60px !important;
}

.top-112 {
    top: 112px;
}

.arrow-btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.height-300 {
    max-height: 262px;
}

.shop-times {
    *::-webkit-scrollbar-thumb {
        background-color: $transparent !important;
        border-radius: 20px !important;
        border: 2px solid $transparent !important;
    }

    *::-webkit-scrollbar-track {
        background: $transparent !important;
        border-radius: 8px !important;
    }

    margin-top: 20px;
    height: 348px;
}

.gap-10 {
    gap: 5px;
}

.gap-100 {
    gap: 10px;
}

.gap-7 {
    gap: 7px;
}

.quantity-prod {
    width: 66px;
    height: 48px;
}

.text-align-center {
    input {
        text-align: center;
    }
}

.container-suggestion {
    background: $white;
    border-radius: 8px;
    z-index: 100;
    overflow-x: auto;
    max-height: 150px;

    div {
        min-height: 32px;
        padding: 6px 12px;
    }
}

.wheel.keen-slider {
    display: flex;
    flex-direction: column;
    color: $primary100;
    height: 100%;
    overflow: visible;
    width: 100%;
}

.wheel--perspective-right .wheel__inner {
    perspective-origin: none;
    transform: none;
    -webkit-transform: none;
}

.wheel--perspective-left .wheel__inner {
    perspective-origin: none;
    transform: none;
    -webkit-transform: none;
}

.wheel__inner {
    display: flex;
    align-items: center;
    justify-content: start;
    perspective: none;
    transform-style: none;
    height: 16%;
    width: 100%;
}

.wheel__slides {
    height: 100%;
    position: relative;
    width: 100%;
}

.wheel__shadow-top,
.wheel__shadow-bottom {
    background: $transparent;
    left: 0;
    height: calc(42% + 2px);
    width: 100%;
    border-bottom: 0.5px solid $primary100;
    position: relative;
    margin-top: -2px;
    z-index: 5;
}

.wheel__shadow-bottom {
    background: $transparent;
    margin-top: 2px;
    border-bottom: none;
    border-top: 0.5px solid $primary100;
}

.wheel__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 1;
    margin-top: 1px;
    margin-left: 5px;
}

.wheel__slide {
    align-items: center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    height: 100%;
    width: 100%;
    position: absolute;
    justify-content: flex-end;
}

span {
    display: inline;
}

.modal-general-conditions {
    width: 700px !important;
    height: 700px !important;
    min-width: 300px !important;
    min-height: 300px !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;

    .content {
        color: $white !important;
        font-family: $lato !important;
        font-size: 0.8rem;
        background-color: $transparent !important;
    }
}



.product-card-bon-plan {
    height: 270px !important;
    width: 230px !important;

    img {
        width: 214px !important;
        height: 141px !important;
    }
}

// *::-webkit-scrollbar-thumb {
//     background-color: $transparent !important;
//     border-radius: 0px;
//     border: 0.5px solid $transparent !important;
// }

// *::-webkit-scrollbar {
//     width: 1px !important;
//     height: 1px !important;
//     border-radius: 0px !important;
// }

// *::-webkit-scrollbar-track {
//     background: $transparent !important;
//     border-radius: 0px;
// }

.modal-details-cmd {
    width: 328px !important;
    height: 548px !important;
    min-width: 328px !important;
    min-height: 548px !important;

    img {
        width: 272px !important;
        height: 140px !important;
    }
}

.text-overflow-hidden {
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
    width: 457px;
}

.compose-min-modal {
    width: 328px !important;
    height: auto !important;
    max-height: auto !important;
    min-width: 328px !important;
    min-height: 440px !important;
    max-width: 328px !important;

    img {
        width: 273px;
        height: 204px;
    }
}

.ds-mhp-52 {
    min-height: 52px;
}

.comment-pt-24 {
    padding-top: 24px;
}

.comment-mt-24 {
    margin-top: 24px;
}

.comment-pb-24 {
    padding-bottom: 24px;
}

.border-none {
    border: none;
    border-radius: 8px;
}

.border-primary10 {
    border: 1px solid $primary40 !important;
    border-color: $primary40 !important;
}

@media (max-width:1024px) {
    .product-details-modal {
        width: 328px;
        height: max-content;
        max-height: 640px;
        min-height: 300px !important;
        overflow: auto !important;
        padding: 6px !important;

        .name-class {
            text-overflow: ellipsis;
            overflow: hidden;
            width: inherit !important;
        }

        .details-product {
            display: flex;
            flex-direction: column;
            padding: 6px;
            width: 100%;
        }

        .ds-p-24 {
            padding: 2px 16px;
        }

        img {
            width: 276px;
            height: 204px;

        }

        *::-webkit-scrollbar-thumb {
            background-color: $primary10 !important;
            border-radius: 20px;
            border: 2px solid $white !important;
        }

        *::-webkit-scrollbar-track {
            background: red !important;
            border-radius: 8px !important;
        }

        .ds-p-24 {
            padding: 2px 16px;
        }

        img {
            width: 276px;
            height: 204px;

        }

        .ds-switch {
            height: 48px;
            width: 50px;
        }

        .ds-switch input {
            height: 40px;
            width: 100px;
            border-radius: 12px;
        }

        .ds-switch input::after {
            content: "Gr";
            left: 26px;
            top: 10px;
        }

        .ds-switch input::before {
            content: "Kg";
            right: -44px;
            top: 10px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 2px;
            bottom: 2px;
            background: #FFFFFF;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            border-radius: 10px;
            width: 49px;
            height: 36px;
        }

        .ds-switch input:checked+label {
            left: 49px;
            right: -47px;
            top: 2px;
            bottom: 2px;
            height: 36px;
            width: 49px;
            border-radius: 10px;
        }

        .ds-switch label {
            top: 2px;
            bottom: 2px;
            border-radius: 10px;
            height: 36px;
        }

        .quatity-input {
            height: 40px !important;
            width: 66px;

            input {
                text-align: center;
            }
        }

        .quantity-btn {
            height: 40px !important;
            width: 40px !important;
            padding: 0;
            cursor: default;
            pointer-events: auto;
        }

        .ds-mr-50 {
            margin-right: 10px !important;
        }

        .nbr-pt-24 {
            padding-top: 16px;
        }
    }
}

//xs
@media (max-width:576px) {
    .modal-add-note {
        .ds-p-24 {
            padding: 6px;
        }
    }

    .padding-class {
        padding: 0 !important;
    }

    .quantity-prod {
        width: 66px;
        height: 40px;
        padding: 0 !important;
    }

    .comment-pt-24 {
        margin-top: 16px;
        padding-top: 0;
    }

    .comment-mt-24 {
        margin-top: 24px;
    }

    .comment-pb-24 {
        margin-bottom: 16px;
        padding-bottom: 0;
    }

    .planify-checkbox .ds-mr-18 {
        margin-right: 8px;
    }

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

    .content-padding {
        padding: 10px;
    }

    .content-modal {
        max-width: 500px;
    }

    .continue-text {

        font-size: 10px;
        width: 400px;

    }

    .details-product {
        display: flex;
        flex-direction: column;
        padding: 16px;
        width: 100%;
    }

    .product-details-modal {
        width: 328px;
        height: 670px;
        min-height: 300px !important;
        overflow: auto !important;

        .ds-p-24 {
            padding: 2px 16px;
        }

        img {
            width: 276px;
            height: 204px;

        }

        .ds-switch {
            height: 48px;
            width: 50px;
        }

        .ds-switch input {
            height: 40px;
            width: 100px;
            border-radius: 12px;
        }

        .ds-switch input::after {
            content: "Gr";
            left: 26px;
            top: 10px;
        }

        .ds-switch input::before {
            content: "Kg";
            right: -44px;
            top: 10px;
        }

        .ds-switch input:checked+label {
            left: 47px;
            right: -47px;
            top: 2px;
            bottom: 2px;
            height: 36px;
        }

        .ds-switch label {
            top: 2px;
            bottom: 2px;
            border-radius: 10px;
            height: 36px;
        }

        .quatity-input {
            height: 40px !important;
            width: 66px;

            input {
                text-align: center;
            }
        }

        .quantity-btn {
            height: 40px !important;
            width: 40px !important;
            padding: 0;
            cursor: default;
            pointer-events: auto;
        }

        .ds-mr-50 {
            margin-right: 10px !important;
        }
    }

    .modal-details-cmd {
        img {
            width: 296px !important;
            height: 164px !important;
            margin-top: 10px;
        }
    }

    .modal-compose-formule {
        width: 346px !important;
        height: 440px !important;
        min-width: 328px !important;
        min-height: 628px !important;

        img {
            width: 273px;
            height: 204px;
        }

        .description-width {
            width: 263px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

.save-card-checkbox {
    .ds-checkbox:not(.switch):not(:checked) {
        border: 1px solid $primary50;
        border-radius: 3px;
    }

    .ds-checkbox:checked {
        background: $primary100 !important;
        border: 1px solid #E0F2F4 !important;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
        border-radius: 3px;

    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
        border-radius: 3px;

    }

    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: 300px !important;
    }
}

.delivery-address {
    .default-input {
        border: 1px solid #E4E4E4 !important;
    }
}
.saved-address{
    line-height: 14px !important;
    font-size: 12px !important;
    margin-bottom: 6px;
    display: block;
}
.color-saved-adr{
    color: $primary40 !important
    }
//sm
@media (min-width:576px) {
    .product-card-bon-plan {
        height: 270px !important;
        width: calc(100vw / 4 - 88px) !important;
    }

    .product-card-bon-plan {
        height: 270px !important;
        width: 230px !important;
    }

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

    .content-padding {
        padding: 20px;
    }

    .content-modal {
        max-width: 500px;
    }

    .continue-text {
        font-size: 10px;
        width: 330px;

    }

    .modal-compose-formule {
        width: 346px !important;
        height: 440px !important;
        min-width: 328px !important;
        min-height: 628px !important;

        img {
            width: 245px;
            height: 204px;
        }

        .description-width {
            width: 258px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: 300px !important;
    }
}

//md
@media (min-width:768px) {

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

    .content-padding {
        padding: 100px;
    }

    .content-modal {
        max-width: 500px;
    }

    .continue-text {
        font-size: 12px;
        width: 400px;

    }

    .compose-txt {
        font-size: 16px !important;
        line-height: 19px !important;
        font-weight: 700px !important;
    }

    .supplement_txt {
        font-size: 10px;
    }

    .modal-compose-formule {
        width: 346px !important;
        height: 440px !important;
        min-width: 328px !important;
        min-height: 628px !important;

        img {
            width: 245px;
            height: 204px;
        }
    }

    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: 300px !important;
    }

    .info-class {
        height: 90px !important;
        overflow-y: auto !important;

    }

    .info-class-btn {
        height: 41px !important;
        overflow-y: auto !important;

    }
}

@media (min-width:1200px) and (max-width:1400px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 4 - 100px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:1100px) and (max-width:1200px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 3 - 105px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:1025px) and (max-width:1100px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 3 - 105px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:992px) and (max-width:1024px) {
    .compose-min-modal{
        .ds-p-16 {
            padding: 6px !important;
        }
        .description-width {
            width: 292px !important;
        }
    }
        
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 3 - 108px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:983px) and (max-width:992px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 2 - 286px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:893px) and (max-width:982px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 2 - 281px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:828px) and (max-width:892px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 3 - 89px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:793px) and (max-width:827px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 3 - 66px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:769px) and (max-width:792px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .default-card.type3 {
            width: calc(100vw / 3 - 55px) !important;
            height: 240px !important;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }
}

@media (min-width:758px) and (max-width:770px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 175px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:748px) and (max-width:757px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 170px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:738px) and (max-width:747px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 165px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:728px) and (max-width:737px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 160px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:718px) and (max-width:727px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 155px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:708px) and (max-width:717px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 150px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:700px) and (max-width:707px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 145px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:689px) and (max-width:699px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 140px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:680px) and (max-width:688px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 135px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:670px) and (max-width:679px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 130px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:661px) and (max-width:669px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 125px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:653px) and (max-width:660px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 120px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:640px) and (max-width:652px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 117px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:623px) and (max-width:639px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 110px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:581px) and (max-width:622px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 102px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:561px) and (max-width:580px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 80px) !important;
            height: 240px !important;
        }
    }
}

@media (min-width:360px) and (max-width:560px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 638px;
        min-width: 300px;
        min-height: 637px;

        .default-card.type3 {
            width: calc(100vw / 2 - 52px) !important;
            height: 260px !important;
        }
    }
}

@media (max-width:768px) {
    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }

    .modal-Delivery-data {
        width: 395px !important;
        height: 622px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .ds-p-24 {
            padding: 6px;
        }

        .ds-switch input {
            appearance: none;
            height: 40px !important;
            width: 360px !important;
            border-radius: 12px;
            background-color: #E6EAEB;
            border: 1px solid #E6EAEB;
            outline: none;
        }

        .label-before {
            position: absolute !important;
            top: 9px !important;
            left: 232px !important;
            white-space: nowrap !important;
            z-index: 0 !important;
        }

        .label-after {
            position: absolute !important;
            z-index: 0 !important;
            width: 160px;
        }

        .ds-switch input:checked+label {
            left: 178px;
            top: 1.5px;
            background: #FFFFFF;
            transition: left 0.4s right 0.4s 0.4s;
            width: 180px;
            height: 37px;
            border-radius: 12px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 1.5px;
            width: 180px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 12px;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        }
    }

    .modal-Delivery-order {
        width: 395px !important;
        height: 558px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .ds-p-24 {
            padding: 6px;
        }

        .ds-switch input {
            appearance: none;
            height: 40px !important;
            width: 360px !important;
            border-radius: 12px;
            background-color: #E6EAEB;
            border: 1px solid #E6EAEB;
            outline: none;
        }

        .label-before {
            position: absolute !important;
            top: 9px !important;
            left: 232px !important;
            white-space: nowrap !important;
            z-index: 0 !important;
        }

        .label-after {
            position: absolute !important;
            z-index: 0 !important;
            width: 125px;
        }

        .ds-switch input:checked+label {
            left: 178px;
            top: 1.5px;
            background: #FFFFFF;
            transition: left 0.4s right 0.4s 0.4s;
            width: 180px;
            height: 37px;
            border-radius: 12px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 1.5px;
            width: 180px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 12px;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        }
    }

    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left {
        visibility: hidden;
    }

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: inherit !important;
    }

    .description-width {
        width: 300px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        line-height: 16px;
    }

}

@media (min-width:1646px) and (max-width:1745px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1616px) and (max-width:1646px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1582px) and (max-width:1616px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1562px) and (max-width:1581px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1535px) and (max-width:1562px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1504px) and (max-width:1535px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1474px) and (max-width:1504px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1444px) and (max-width:1473px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1415px) and (max-width:1444px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1400px) and (max-width:1415px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1355px) and (max-width:1400px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1330px) and (max-width:1355px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1300px) and (max-width:1330px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1271px) and (max-width:1299px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1250px) and (max-width:1270px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1235px) and (max-width:1249px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid #F7F7F7;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1200px) and (max-width:1234px) {
    .modal-compose-formule {
        width: 900px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 600px !important;

        .item-card {
            gap: 8px;
            width: 148px;
            height: 196px;
            border: 1px solid $neutral4;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1187px) and (max-width:1200px) {
    .modal-compose-formule {
        width: 950px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 650px !important;

        .item-card {
            gap: 8px;
            width: 186px;
            height: 196px;
            border: 1px solid $neutral4;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1100px) and (max-width:1187px) {
    .modal-compose-formule {
        width: 950px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 650px !important;

        .item-card {
            gap: 8px;
            width: 180px;
            height: 200px;
            border: 1px solid $neutral4;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

@media (min-width:1024px) and (max-width:1100px) {

    .modal-compose-formule {
        width: 950px !important;
        height: 650px !important;
        min-width: calc(100% - 40px) !important;
        min-height: 650px !important;

        .item-card {
            gap: 8px;
            width: 150px;
            height: 200px;
            border: 1px solid $neutral4;
        }

        .border-secondary30 {
            left: 75%;
            bottom: 8px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }
    }
}

//lg
@media (min-width:992px) {
    .modal-add-note {
        width: 500px;
        height: 450px;
        min-width: 300px !important;
        min-height: 300px !important;
    }

    .content-modal {
        max-width: 100%;
    }

    .continue-text {
        font-size: 12px;
        width: 400px;

    }

    .overflow-hidden {
        overflow: auto !important;
    }

    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: 300px !important;
    }

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

}
@media (min-width:1024px){
    .text-overflow-hidden {
        text-overflow: ellipsis;
        white-space: pre-line;
        overflow: hidden;
        width: inherit !important;
    }
}

.modal-connexion {
    overflow: hidden !important;
}

@media (min-width:901px) and (max-width:1024px) {
    .compose-form-class {
        overflow: hidden !important;
    }

    .modal-compose-formule {
        width: 328px !important;
        height: calc(100vh - 10px) !important;
        max-height: 628px !important;
        min-width: 328px !important;
        min-height: 440px !important;
        overflow: hidden !important;

        .ds-p-16 {
            padding: 6px !important;
        }

        .item-card {
            gap: 8px !important;
            width: calc(100% / 2 - 9px) !important;
            height: 225px !important;
            border: 1px solid #F7F7F7 !important;
        }

        .border-secondary30 {
            left: 70%;
            bottom: 6px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }

        .product-class {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.height-40 {
    height: calc(100vh - 13px);
}

@media only screen and (min-device-width: 12in) {
    .height-60 {
        height: calc(100vh - 65px);
    }
}

@media (max-width:1535) {
    .height-40 {
        height: 100vh;
    }
}

@media (min-width:360px) and (max-width:1024px) {
    .height-60 {
        height: 100%;
    }
}

@media (min-width:1650px) and (max-width:2000px) {
    .height-40 {
        height: calc(100vh - 220px);
    }

    .height-60 {
        height: calc(100vh - 196px);
    }
}

@media (min-width:360px) and (max-width:379px) {
    .width-60 {
        width: calc(100vw - 66px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 60px);
        }
    }
}

@media (min-width:380px) and (max-width:399px) {
    .width-60 {
        width: calc(100vw - 90px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 80px);
        }
    }
}

@media (min-width:400px) and (max-width:419px) {
    .width-60 {
        width: calc(100vw - 103px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 100px);
        }
    }

}

@media (min-width:420px) and (max-width:439px) {
    .width-60 {
        width: calc(100vw - 123px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 120px);
        }
    }
}

@media (min-width:440px) and (max-width:459px) {
    .width-60 {
        width: calc(100vw - 143px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 140px);
        }
    }
}

@media (min-width:460px) and (max-width:479px) {
    .width-60 {
        width: calc(100vw - 163px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 160px);
        }
    }
}

@media (min-width:480px) and (max-width:499px) {
    .width-60 {
        width: calc(100vw - 188px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 180px);
        }
    }
}

@media (min-width:500px) and (max-width:519px) {
    .width-60 {
        width: calc(100vw - 210px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 200px);
        }
    }
}

@media (min-width:520px) and (max-width:539px) {
    .width-60 {
        width: calc(100vw - 231px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 220px);
        }
    }
}

@media (min-width:540px) and (max-width:559px) {
    .width-60 {
        width: calc(100vw - 261px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 240px);
        }
    }
}

@media (min-width:560px) and (max-width:579px) {
    .width-60 {
        width: calc(100vw - 291px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 260px);
        }
    }
}

@media (min-width:580px) and (max-width:599px) {
    .width-60 {
        width: calc(100vw - 310px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 280px);
        }
    }
}

@media (min-width:600px) and (max-width:619px) {
    .width-60 {
        width: calc(100vw - 310px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 300px);
        }
    }
}

@media (min-width:620px) and (max-width:639px) {
    .width-60 {
        width: calc(100vw - 331px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 320px);
        }
    }
}

@media (min-width:640px) and (max-width:659px) {
    .width-60 {
        width: calc(100vw - 361px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 340px);
        }
    }
}

@media (min-width:660px) and (max-width:679px) {
    .width-60 {
        width: calc(100vw - 391px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 360px);
        }
    }
}

@media (min-width:680px) and (max-width:699px) {
    .width-60 {
        width: calc(100vw - 410px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 380px);
        }
    }
}

@media (min-width:700px) and (max-width:719px) {
    .width-60 {
        width: calc(100vw - 410px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 400px);
        }
    }
}

@media (min-width:720px) and (max-width:739px) {
    .width-60 {
        width: calc(100vw - 431px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 420px);
        }
    }
}

@media (min-width:740px) and (max-width:759px) {
    .width-60 {
        width: calc(100vw - 461px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 440px);
        }
    }
}

@media (min-width:760px) and (max-width:779px) {
    .width-60 {
        width: calc(100vw - 491px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 460px);
        }
    }
}

@media (min-width:780px) and (max-width:799px) {
    .width-60 {
        width: calc(100vw - 510px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 580px);
        }
    }
}

@media (min-width:800px) and (max-width:819px) {
    .width-60 {
        width: calc(100vw - 510px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 500px);
        }
    }
}

@media (min-width:820px) and (max-width:839px) {
    .width-60 {
        width: calc(100vw - 531px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 520px);
        }
    }
}

@media (min-width:840px) and (max-width:859px) {
    .width-60 {
        width: calc(100vw - 561px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 540px);
        }
    }
}

@media (min-width:860px) and (max-width:879px) {
    .width-60 {
        width: calc(100vw - 591px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 560px);
        }
    }
}

@media (min-width:880px) and (max-width:899px) {
    .width-60 {
        width: calc(100vw - 610px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 580px);
        }
    }
}

@media (min-width:900px) and (max-width:919px) {
    .width-60 {
        width: calc(100vw - 610px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 600px);
        }
    }
}

@media (min-width:920px) and (max-width:939px) {
    .width-60 {
        width: calc(100vw - 631px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 620px);
        }
    }
}

@media (min-width:940px) and (max-width:959px) {
    .width-60 {
        width: calc(100vw - 661px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 640px);
        }
    }
}

.product-details-modal .quatity-input input {
    color: $primary100 !important;
}

@media (min-width:960px) and (max-width:979px) {
    .width-60 {
        width: calc(100vw - 657px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 660px);
        }
    }
}

@media (min-width:980px) and (max-width:999px) {
    .width-60 {
        width: calc(100vw - 710px);
        overflow: hidden;
    }

    .modal-general-conditions {
        .content {
            color: $white !important;
            font-family: $lato !important;
            font-size: 0.8rem;
            background-color: $transparent !important;
            width: calc(100vw - 680px);
        }
    }
}

@media (min-width:360px) and (max-width:992px) {
    .refill-class {
        .ds-pt-40 {
            padding-top: 27px;
        }
    }

    .my-sites-class {
        .ds-pt-40 {
            padding-top: 27px;
        }
    }

    .border-none {
        width: 60px;

        .padding-multi-select {
            padding-left: 8px;
        }
    }

    .modal-general-conditions {
        width: 328px !important;
        height: 630px !important;
        min-width: 300px !important;
        min-height: 300px !important;
        overflow-x: hidden !important;
        overflow-y: auto !important;

        .height-40 {
            height: 100%;
            width: 100%;
        }
    }

    .modal-details-cmd {
        img {
            width: 296px !important;
            height: 164px !important;
            margin-top: 10px;
        }
    }

    .modal-connexion {
        width: 328px !important;
        height: 580px !important;
        min-width: 328px !important;
        min-height: 580px !important;

        .slash-icon {
            position: absolute;
            top: 50%;
        }

        .ds-p-40 {
            padding: 6px;
        }

    }

    .content-social {
        padding: 12px 30px;
    }

    .subscription-modal {
        .ds-p-30 {
            padding: 6px;
        }
    }
}

@media (min-width:360px) and (max-width:900px) {
    
    .modal-connexion {
        width: 328px !important;
        height: 580px !important;
        min-width: 328px !important;
        min-height: 580px !important;

        .slash-icon {
            position: absolute;
            top: 50%;
        }

        .ds-p-40 {
            padding: 6px;
        }

    }

    .content-social {
        padding: 12px 30px;
    }

    .subscription-modal {
        .ds-p-24 {
            padding: 6px;
        }
    }

    .compose-form-class {
        overflow: hidden !important;
    }

    .modal-compose-formule {
        width: 328px !important;
        height: 628px !important;
        min-width: 328px !important;
        min-height: 628px !important;
        overflow: hidden !important;

        .ds-p-16 {
            padding: 6px !important;
        }

        .item-card {
            gap: 8px !important;
            width: calc(100% / 2 - 9px) !important;
            height: 210px !important;
            border: 1px solid #F7F7F7 !important;
        }

        .border-secondary30 {
            left: 70%;
            bottom: 6px;
            height: 24px;
            border-radius: 6px;
            z-index: 1;
            position: absolute;
        }

        .product-class {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@media (min-width:1288px) and (max-width:1600px) {
    .modal-compose-formule {
        width: 1000px !important;
        height: 704px !important;
        min-width: 1000px !important;
        min-height: 704px !important;

        img {
            width: 387px;
            height: 268px;
        }

        .default-btn.primary:disabled {
            background-color: $primary10;
        }

        .default-btn.primary:focus {
            background-color: $primary100 !important;
        }

        .default-btn.primary:hover {
            background-color: $primary70 !important;
        }
    }
}

//xl
@media (min-width:1200px) and (max-width:1284px) {

    .default-input,
    .default-input:focus {
        font-size: 14px !important;
    }

    .content-modal {
        min-width: 900px;
        max-width: 1200px
    }

    .modal-compose-formule {
        width: 1000px !important;
        height: 650px !important;
        min-width: 1000px !important;
        min-height: 650px !important;

        img {
            width: 387px;
            height: 268px;
        }

        .default-btn.primary:disabled {
            background-color: $primary10;
        }

        .default-btn.primary:focus {
            background-color: $primary100 !important;
        }

        .default-btn.primary:hover {
            background-color: $primary70 !important;
        }
    }

    .modal-compose-option-formule {
        width: 500px !important;
        height: 700px !important;
        min-width: 500px !important;
        min-height: 700px !important;
    }

}

@media (max-width :1200px) {

    .default-input,
    .default-input:focus {
        font-size: 14px !important;
    }

}

.modal-plan-container {
    height: calc(100% - 10px);
}

.tab-bonplan {
    justify-content: center;
    align-items: center;
}

.tab-bonplan::-webkit-scrollbar,
.tab-bonplan::-webkit-scrollbar-thumb,
.tab-bonplan::-webkit-scrollbar-track {
    display: none !important;
}

.site-info-tab {
    justify-content: center;
    align-items: center;
}

@media ((min-width: 360px) and (max-width: 400px)) {
    .site-modal {

        .site-info-tab {
            .ds-text-size-16 {
                font-size: 14px;
            }

            .ds-overflow-y {
                overflow-y: hidden !important;
            }

            .ds-px-12 {
                padding: 0 5px;
            }
        }
    }
}

@media ((min-width: 400px) and (max-width: 900px)) {
    .site-modal {
        .site-info-tab {
            .ds-px-12 {
                padding: 0 5px;
            }
        }
    }
}

@media ((min-width: 360px) and (max-width: 900px)) {
    .info-class {
        height: 110px !important;
        overflow-y: auto !important;

    }

    .info-class-btn {
        height: 61px !important;
        overflow-y: auto !important;

    }

    .product-details-modal {
        width: 328px;
        height: 670px;
        min-height: 300px !important;
        overflow: auto !important;

        .ds-p-24 {
            padding: 2px 16px;
        }

        img {
            width: 276px;
            height: 204px;

        }

        .ds-switch {
            height: 48px;
            width: 50px;
        }

        .ds-switch input {
            height: 40px;
            width: 100px;
            border-radius: 12px;
        }

        .ds-switch input::after {
            content: "Gr";
            left: 26px;
            top: 10px;
        }

        .ds-switch input::before {
            content: "Kg";
            right: -44px;
            top: 10px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px !important;
            top: 2px !important;
            bottom: 0px !important;
            height: 36px !important;
            border-radius: 10px !important;
            width: 49px !important;
        }

        .ds-switch input:checked+label {
            left: 49px !important;
            right: -48px !important;
            top: 2px !important;
            height: 36px !important;
            border-radius: 10px !important;
            width: 49px !important;

        }

        .ds-switch label {
            border-radius: 10px;
            height: 36px;
        }

        .quatity-input {
            height: 40px !important;
            width: 66px;

            input {
                text-align: center;
            }
        }

        .quantity-btn {
            height: 40px !important;
            width: 40px !important;
            padding: 0;
            cursor: default;
            pointer-events: auto;
        }

        .ds-mr-50 {
            margin-right: 10px !important;
        }
    }

    .site-modal {
        .site-info-tab {
            .ds-px-12 {
                padding: 0 5px;
            }
        }
    }

    .city-class {
        .ds-text-line-28 {
            line-height: 24px !important;
        }

        .ds-text-size-36 {
            font-size: 25px !important;
        }

        .ds-text-line-43 {
            line-height: 33.6px !important;
        }
    }

    .default-input,
    .default-input:focus {
        font-size: 12px !important;
    }

    .modal-Bon-plan {
        width: 1056px;
        height: 620px;
        min-width: 300px;
        min-height: 600px;

        .tab-bonplan::-webkit-scrollbar,
        .tab-bonplan::-webkit-scrollbar-thumb,
        .tab-bonplan::-webkit-scrollbar-track {
            display: none !important;
        }

        .tab-bonplan {
            justify-content: start;
            align-items: start;
        }

        .horizontal-line {
            margin: 0 !important;
            width: 100% !important;
        }

        .ds-py-32 {
            padding: 6px;
        }

        .default-card.type3 .availibility {
            top: 54% !important;
            border-radius: 0px 8px 8px 0px !important;
        }
    }

    .devenir-commercant {
        .become-retailer {
            display: none;
        }

        .retailer {
            margin-left: 0 !important;
        }

        .btn-devenir-commercant {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            margin-top: 7px;
        }
    }
}

.modal-plan-container {
    height: calc(100% - 10px);
    overflow-x: auto;
}

.arrow-btn {
    display: none;
}

.arrow-time:hover {
    .arrow-btn {
        display: grid;
    }
}

@media (max-width: 576px) {

    .default-input,
    .default-input:focus {
        font-size: 12px !important;

    }

    .content-modal {
        max-width: 500px !important;
    }

    .contact-us-modal {
        width: 500px !important;
        max-height: 600px !important;
        min-width: 300px !important;
        min-height: 300px !important;
        overflow: auto !important;

        .ds-px-40 {
            padding: 6px;
        }
    }

    .modal-Bon-plan {
        max-width: 700px !important;
        height: 600px;
        min-width: 300px;
        min-height: 500px;
    }

    .horizontal-bonPlan {
        width: 300px !important;
    }

    .devenir-commercant {

        .become-retailer {
            display: none;
        }

        .retailer {
            margin-left: 0 !important;
        }

        .btn-devenir-commercant {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
        }
    }
}

.height-94 {
    height: calc(100% - 94px) !important;
}

@media (min-width:1600px) {
    .modal-Bon-plan .product-card-bon-plan {
        height: 260px !important;
        width: calc(25vw - 251px) !important;
    }

}

@media (max-width:992px) {
    .compose-min-modal {
        .description-width {
            width: 292px !important;
        }

        .ds-p-16 {
            padding: 6px !important;
        }
    }

    .height-100 {
        height: auto !important;
    }

    .shop-times {
        height: 300px;
    }
    
}
@media (max-width:390px) {

    .shop-times {
        height: 290px;
    }
    
}

@media (max-width: 360px){
    .modal-compose-formule {
        width: 328px !important;
        height: 628px !important;
        min-width: 328px !important;
        min-height: 628px !important;
        overflow: hidden !important;

        .ds-p-16 {
            padding: 6px !important;
        }

        .item-card {
            gap: 8px !important;
            width: calc(100% / 2 - 9px) !important;
            height: 210px !important;
            border: 1px solid #F7F7F7 !important;
        }

    }
}