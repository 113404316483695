.ds-radio {
    border: 2px solid $white;
    appearance: none;
}

.ds-radio:checked  {
    + label{
        color: $secondary100;
    }
    box-shadow: 0 0 0 1px $secondary100;
    background-color: $secondary100;
}

.ds-radio:not(:checked)  {
    box-shadow: 0 0 0 1px $neutral100 ;
    background-color: $white;
}

.ds-radio:disabled {
    + label{
        color: $neutral10;
    }
    box-shadow: 0 0 0 1px $neutral10;
    background-color: $white;
}

.ds-radio.invalid {
    + label{
        color: $error100;
    }
    box-shadow: 0 0 0 1px $error100;
}

.ds-radio.valid {
    + label{
        color: $success100;
    }
    box-shadow: 0 0 0 1px $success100;
}
