.alert-notification {
  position: fixed;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  z-index: 100000000000000;
  width: 50%;
  align-items: center;
  display: flex;
  top: 92%;
  bottom: 0 !important;
  left: 22%;
}

.success {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
}

.error {
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
}

.warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border: 1px solid #faebcc;
}

.info {
  background-color: $primary100;
  color: $white;
}

.message {
  margin-right: 10px;
}

@media (max-width: 360px) {
  .message {
    font-size: 12px !important;
  }

  .alert-notification {
    width: 100% !important;
    left: 10%;
    max-width: 100% !important;
  }
}

@media (min-width: 360px) and (max-width:992px) {
  .ds-pt-94{
    padding-top: 64px;
  }
  .message {
    font-size: 12px !important;
  }

  .alert-notification {
    width: 98% !important;
    left: 1%;
    right: 1%;
    max-width: 98% !important;
  }
}
.color-spinner{
  color: $primary70;
  border-bottom-color:$primary70 !important;
}