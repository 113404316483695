.ds-text-avatar {
    place-items: center;
    text-transform: uppercase;
    display: grid;
}

.ds-container-avatar {
    display: inline-block;
}

.ds-img-avatar {
    border: 2px solid $white;
}

.ds-status-avatar {
    border: 2px solid $white;
    bottom: 0;
    right: 0;
}

@media(max-width: 768px) {
    .justify-content {
        justify-content: center;
    }

    .ds-text-size-24,
    .ds-text-size-28,
    .ds-text-size-36,
    .ds-text-size-34,
    .ds-text-size-20 {
        font-size: 16px;
    }
    .ds-text-line-24,
    .ds-text-line-28,
    .ds-text-line-36,
    .ds-text-line-34 {
        line-height: 20px;
    }

    .compte-container {
        .ds-text-size-20 {
            font-size: 16px;
        }

        .text-size-responsive {
            font-size: 12px !important;
        }

        button {
            font-size: 16px !important;
        }

        .ds-text-size-16 {
            font-size: 16px;
        }

        .ds-text-14 {
            font-size: 14px !important;
        }

        #avatar.ds-hp-64 {
            height: 48px !important;
        }

        #avatar.ds-wp-64 {
            width: 48px !important;
        }
    }
}