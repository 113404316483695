.default-btn {
    border: 0rem solid;
    font-style: normal;
    font-weight: 700;
}
.default-btn.default-btn:active {
    opacity: 1;
}

.default-btn.primary {
    border-color: none;
    color: $white;
}

.default-btn.primary:hover {
    background-color: $primary70;
}

.default-btn.primary:disabled {
    background-color: $primary10;
}

.default-btn.primary:active {
    background-color: $primary100;
    border: none;
}

.default-btn.primary:focus {
    background-color: $primary70;
    border-color: none;
}

.default-btn.secondary {
    border-color: none;
    color: $primary100;
}

.default-btn.secondary:hover {
    background-color: $secondary50;
}

.default-btn.secondary:disabled {
    border-color: none;
    background-color: $secondary10;
    color: $white;
}

.default-btn.secondary:active {
    background-color: $secondary100;
    border: none;
}

.default-btn.secondary:focus {
    background-color: $secondary70;
    border-color: none;
}


.default-btn.tertiary {
    border-color: $primary100;
    border-width: 0.1rem;
}

.default-btn.tertiary:hover {
    border-color: $primary70;
    color: $primary70;
}

.default-btn.tertiary:focus {
    border-color: $primary100;
    color: $primary100;
}

.default-btn.tertiary:active {
    border-color: $primary100;
    color: $primary100;
}

.default-btn.tertiary:disabled {
    border-color: $primary10;
    color: $primary10;
}

.default-btn.quaternary {
    border: none;
    background-color: $transparent;
}

.default-btn.quaternary:hover {
    border-color: $primary70;
    color: $primary70;
}

.default-btn.quaternary:focus {
    border-color: $primary100;
    color: $primary100;
}

.default-btn.quaternary:active {
    border-color: $primary100;
    color: $primary100;
}

.default-btn.quaternary:disabled {
    border-color: $primary10;
    color: $primary10;
}
.primary-70-btn{
    color: $primary70 !important;
    border:1px solid  $primary70 !important;
    height: 48px !important;
}
.primary-20-btn{
    border-color: none;
    background-color: $primary20;  
}
.primary-10-btn{
    border: none;
    border-color: none;
    background-color: $primary10;

}
.parimary-70-btn{
    height: 48px !important;
    border-color: $primary70 !important;
    border: 1px solid $primary70 !important;
    color: $primary70 !important;
}