.default-input {
    border: 1px solid $primary70;
    font-style: normal;
    font-size: 14px !important;
    line-height: 21px;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    font-size: 14px !important;
}


.default-input::placeholder {
    color: $primary20 !important;
    //padding-left: 10px;
    overflow: hidden;
    font-size: 14px !important;
    opacity: 1;

}

.default-input:disabled {
    cursor: not-allowed;
    font-size: 14px !important;

}

.invalid, .react-tel-input .invalid {
    border: 1px solid $error100 !important;
}

.valid {
    border: 1px solid $success100;
}

.default-select {
    display: flex;

}

.ds-select-value {
    height: 24px;
    font-size: 14px !important;

}

.container-option-drop {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
    max-height: 96px;
    overflow-y: auto;

    input {
        zoom: 60%;
    }
}

.ds-input-select {
    border: none !important;
    display: inline-block;
    outline: none;
    height: 22px;
    font-size: 14px !important;
}

.ds-input-select:focus,
.ds-input-select:active {
    border: none;
    font-size: 14px !important;
}

.default-select.ds-hp-36 {
    height: auto;
    min-height: 36px;
    font-size: 14px !important;

}

.default-select.ds-hp-40 {
    height: auto;
    min-height: 48px;
    font-size: 14px !important;
}

.default-select.ds-hp-48 {
    height: 48px;
    min-height: 48px;
    font-size: 14px !important;

}

.ds-border-primary20 {
    border: 1px solid $primary20;
}

.text-area-class {
    .default-input {
        white-space: pre-wrap !important;
        overflow: auto !important;
        resize: none;
    }
}

.devenir-commercant {
    height: 100%;

    .fixed-witdh {
        width: 500px;
    }

    .btn-devenir-commercant {
        display: flex;
        align-items: end;
        justify-content: end;
        align-content: end;
    }

    .btn-primary10 {
        background-color: $primary10 !important;
        color: $primary100 !important;
        border-radius: 8px !important;
        width: 150px;
        height: 48px;
    }

    .btn-primary20 {
        background-color: $primary20 !important;
        color: $white !important;
        border-radius: 8px !important;
        width: 150px;
        height: 48px;
    }

}

.quatity-input {

    .ds-pl-16 {
        padding-left: 0px !important;
    }

    .ds-pr-16 {
        padding-right: 0 !important;
    }

    input {
        text-align: center;
    }
}

.product-quantity {
    .ds-pl-16 {
        padding-left: 0px !important;
    }
}