.loading-placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;
    width: 100px;
    color: #b3b3b3;
    animation: placeholder-glow 2s ease-in-out infinite;
}
