* {
    font-family: $lato;
    scrollbar-width: thin;
    scrollbar-color: $primary10 $white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 2px;
    height: 3px;
    border-radius: 18px !important;
}

*::-webkit-scrollbar-track {
    background: $transparent;
    border-radius: 18px;
}

*::-webkit-scrollbar-thumb {
    background-color: $primary20;
    border-radius: 20px;
    border: none;
    width: 1px;
}

@include createContainerUtilities("ds-wv", "width", "vw");
@include createContainerUtilities("ds-w", "width", "%");
@include createContainerUtilities("ds-wp", "width", "px");
@include createContainerUtilities("ds-hv", "height", "vh");
@include createContainerUtilities("ds-mhv", "max-height", "vh");
@include createContainerUtilities("ds-h", "height", "%");
@include createContainerUtilities("ds-hp", "height", "px");

@include createOpacityUtilities("ds-op");

@include createContainerUtilities("ds-m", "margin", "px");
@include createMarginAxesUtilities("ds-mx", "margin-left", "margin-right", "px");
@include createMarginAxesUtilities("ds-my", "margin-bottom", "margin-top", "px");
@include createContainerUtilities("ds-mt", "margin-top", "px");
@include createContainerUtilities("ds-mb", "margin-bottom", "px");
@include createContainerUtilities("ds-mr", "margin-right", "px");
@include createContainerUtilities("ds-ml", "margin-left", "px");

@include createContainerUtilities("ds-p", "padding", "px");
@include createMarginAxesUtilities("ds-px", "padding-left", "padding-right", "px");
@include createMarginAxesUtilities("ds-py", "padding-bottom", "padding-top", "px");
@include createContainerUtilities("ds-pt", "padding-top", "px");
@include createContainerUtilities("ds-pb", "padding-bottom", "px");
@include createContainerUtilities("ds-pr", "padding-right", "px");
@include createContainerUtilities("ds-pl", "padding-left", "px");

@include createUtilitiesColor($colors, "color", "ds-bg", "background-color");
@include createUtilitiesColor($colors, "color", "ds-text", "color");

@include createUtilitiesWeight($weights, "weight", "ds-text", "font-weight");

@include createUtilitiesSize("ds-text-size", "font-size", "px");
@include createUtilitiesSize("ds-text-line", "line-height", "px");

@include createContainerUtilities("ds-borad", "border-radius", "px");
@include createContainerUtilities("ds-radius", "border-radius", "%");

.ds-mw-500{
    max-width: 500px;
}

.site-card{
    position: relative;
    max-width: 360px;
    border-radius: 8px;
    background-color: #fff;
    min-width: 280px;
}

.ds-relative {
    position: relative;
}

.ds-absolute {
    position: absolute;
}

.ds-fixed {
    position: fixed;
}

.ds-sticky {
    position: sticky;
}

.ds-static {
    position: static;
}

.ds-top-15 {
    top: 15px;
}

.ds-top-27 {
    top: 27px;
}

.ds-right-15 {
    right: 15px;
}

.ds-top-7 {
    top: 10px;
    right: 16px !important;
}

.ds-left-15 {
    left: 15px;
}

.ds-pointer {
    cursor: pointer;
}

.ds-disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.ds-z-index-100000 {
    z-index: 100000;
}

.ds-blur0 {
    background: none;
    backdrop-filter: blur(0px);
}

.ds-blur1 {
    background: rgba(17, 24, 39, 0.2);
    backdrop-filter: blur(8px);
}

.ds-blur2 {
    background: rgba(17, 24, 39, 0.2);
    backdrop-filter: blur(16px);
}

.ds-blur3 {
    background: rgba(17, 24, 39, 0.2);
    backdrop-filter: blur(24px);
}

.ds-blur4 {
    background: rgba(17, 24, 39, 0.2);
    backdrop-filter: blur(40px);
}

.ds-box-shadow1 {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ds-box-shadow2 {
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -2px rgba(16, 24, 40, 0.1);
}

.ds-box-shadow3 {
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.ds-box-shadow4 {
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
}

.ds-box-shadow5 {
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.ds-box-shadow6 {
    box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.1);
}

.ds-border-radius-10 {
    border-radius: 50px;
}

.ds-border-left {
    border-left: 1px solid $neutral20;
}

.ds-border-radius-8 {
    border-radius: 8px;
}

.ds-overflow-x {
    overflow-x: auto;
}

.ds-overflow-xy {
    overflow-x: auto;
    overflow-y: hidden;
}

.ds-overflow-y {
    overflow-y: auto;
}

.ds-private-content {
    height: calc(100% - 94px);
}

.ds-h-cal-100percent-34px {
    height: calc(100% - 34px);
}

.ds-height-124 {
    height: calc(100% - 110px);
}

@media (min-width:390px) {
    .ds-height-124 {
        height: calc(100% - 90px);
    }
}

.ds-text-primary50:hover {
    color: $primary50;
}