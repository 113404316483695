.text-gradient {
    background: linear-gradient(to left, transparent, #688385);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ds-height-container {
    height: calc(100vh - 300px);
}

.ds-height-scrol-container {
    height: calc(100vh - 574px);
}

.ds-wp-235 {
    width: 235px;
}

.ds-wp-420 {
    width: 420px;
}

.ds-height-360 {
    height: 360px;
}

.compte-container {
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
}


@media (max-width: 992px) {
    .ds-height-container {
        height: calc(100vh - 500px);
    }
}

@media (max-width: 576px) {
    .compte-container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .footer-container {
        display: block;
    }
}

@media (min-width: 768px) {
    .compte-container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer-container {
        display: block;
    }
}