@charset "UTF-8";


@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-phone-input-2/lib/style.css';
@import 'react-multi-carousel/lib/styles.css';
@import 'react-modern-drawer/dist/index.css';
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap");



@import 'abstracts/variables';
@import 'abstracts/colors';
@import 'abstracts/flex';
@import 'abstracts/font';
@import 'abstracts/grid';
@import 'abstracts/mixins';



@import "components/container";
@import "components/placeholder";
@import "components/button";
@import "components/spinner";
@import 'components/input';
@import 'components/checkbox';
@import 'components/stepper';
@import 'components/avatar';
@import 'components/radio';
@import 'components/text';
@import 'components/navbar';
@import 'components/pricing';
@import 'components/tab';
@import 'components//sidebar';
@import 'components/tab';
@import 'components/modal';
@import 'components/inputfile';
@import 'components/card';
@import 'components/organismStepper';
@import 'components/phoneInput';
@import 'components/basket';
@import 'components/compte';
@import 'components/alert';