.ds-circle-icon {
  background: $success70;
  color: $white;
  text-align: center;
  vertical-align: middle;
}

.ds-step {
  min-height: 1em;
  color: $primary100;
}

.ds-circle {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 100%;
  text-align: center;
}

.ds-circle:after {
  content: '';
  position: absolute;
  top: -60px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 40px;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: $neutral100;
  z-index: -1;
}

.stepper-item {
  flex-direction: column;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid $neutral100;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $neutral100;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  z-index: 5;
  border-radius: 50%;
}

.stepper-item.active::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid $neutral100;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}
