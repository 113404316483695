.default-basket {
    right: 0;
    width: 500px;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x {
    overflow-x: auto !important;
}

.site-info-line {
    border: 1px solid $primary10;
}

.border-primary10 {
    border: 1px solid $primary70 !important;
    height: 24px !important;
    max-width: 61px !important;
    font-size: 12px;
    line-height: 14px;
    padding: 8px;
    border-radius: 6px !important;
}

.image {
    width: 88px;
    height: 88px;
}

.btn-delete,
.btn-delete:hover,
.btn-delete:focus {
    border: 1px solid $error100 !important;
    background-color: $white !important;
    height: 32px !important;
    width: 32px !important;

    svg {
        color: $error100 !important;
    }
}

.quantity-btn {
    border: 1px solid $primary40 !important;
    background-color: $white;
    height: 32px !important;
    width: 32px !important;
    color: $primary40 !important;
}

.quatity-input {
    background-color: $primary10;
    width: 42px;
    height: 32px !important;
    border: none;
    border-radius: 5px;

    input {
        text-align: center;
    }
}

.quatity-input-basket {
    background-color: $primary10;
    width: 42px;
    height: 32px !important;
    border: none;
    border-radius: 5px;

    .ds-pl-16 {
        padding-left: 0px !important;
    }

    input {
        text-align: center;
    }
}

.quatity-input-basket .ds-pr-16 {
    padding-right: 0 !important;

    input {
        text-align: center;
    }
}

.quatity-input-basket .ds-pl-16 {
    padding-left: 0px !important;

    input {
        text-align: center;
    }
}

.height-48 {
    height: 48px !important;
}

.EZDrawer input {
    visibility: visible;
    display: inline;
}

.top-00 {
    top: 23px;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
    z-index: 10000000;
    border-radius: 16px;
    height: 50px;
}

.action-btn {
    justify-content: none;
    align-items: end;
    margin-top: 5px;
}

@media screen and (max-width:1024px) {
    .basket-item-class {
        width: auto;
        height: auto;
    }

    .price-class {
        padding-top: 10px;
    }

    .image-btn {
        display: flex;
        flex-direction: column;
    }

    .action-btn {
        justify-content: none;
        align-items: end;
        margin-top: 5px;
    }

    .description {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 122px;
        height: 40px;
        white-space: nowrap;
    }


    .height-48 {
        height: 40px !important;
    }

}

.border-secondary {
    height: 24px !important;
    width: 53px !important;
    font-size: 12px;
    line-height: 14px;
    background: $secondary10;
}

.description-basket, .description-basket-reduction {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 350px;
    white-space: nowrap;
}

.bassket-class {
    height: calc(100vh - 520px) !important;

}
.bassket-class-frais {
    height: calc(100vh - 580px) !important ;

}
.info-class {
    height: 172px !important;
    overflow-y: auto !important;

}

.info-class-btn {
    height: 115px !important;
    overflow-y: auto !important;

}
.point-address {
    height:  289px !important;
    overflow-y: auto !important;
}
.remove-class {
    padding-top: 10px !important;
}

@media (max-width:992px) {
    .description-basket,.description-basket-reduction {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 160px;
        white-space: nowrap;
    }

    .border-primary10 {
        border: 1px solid $primary70 !important;
        height: 24px !important;
        max-width: 54px !important;
        font-size: 12px !important;
        line-height: 14px;
        padding: 8px;
    }

    .basket-class,
    .comment-coupon-class {
        .horizontal-line {
            margin: 6px 0 !important;
        }
    }

    .fees-class {
        margin: 6px 0 !important;
    }
    .info-class {
        height: 112px !important;
        overflow-y: auto !important;
    }
    
}

@media (max-width:768px) {
.info-class {
    height: 112px !important;
    overflow-y: auto !important;
}

}



@media (max-width:1200px) {
    .bassket-class {
        height: calc(100vh - 497px) !important;

        .remove-class {
            padding-top: 0px !important;
        }
    }
    .bassket-class-frais {
        height: calc(100vh - 552px) !important ;

        .remove-class {
            padding-top: 0px !important;
        }
    }
}

@media (min-width:1100px) and (max-width:1145px) {
    .default-basket {
        width: 500px;
    }
}

@media (min-width:360px) and (max-width:1024px) {
    .description-basket {
        max-width: 160px;
    }
    .description-basket-reduction {
        max-width: 89px;
    }

    .default-basket {
        width: 100% !important;
    }
}

@media (min-width:700px) and (max-width:1199px) {
    .bassket-class {
        height: calc(100vh - 515px) !important;

        .remove-class {
            padding-top: 0px !important;
        }

        .horizontal-line {
            margin-top: 16px !important;
        }

        overflow-x: hidden;
        overflow-y: auto;
    }
    .bassket-class-frais {
        height: calc(100vh - 570px) !important;

        .remove-class {
            padding-top: 0px !important;
        }

        .horizontal-line {
            margin-top: 16px !important;
        }

        overflow-x: hidden;
        overflow-y: auto;
    }

}

@media (max-width:699px) {
    .bassket-class {
        height: calc(100vh - 500px) !important;
    }
    .bassket-class-frais {
        height: calc(100vh - 555px) !important;
    }

    .remove-class {
        padding-top: 10px !important;
    }

    .info-class {
        height: 102px !important;
        overflow-y: auto !important;

    }

    .info-class-btn {
        height: 63px !important;
        overflow-y: auto !important;

    }

    .point-address {
        height: 267px !important;
        overflow-y: auto !important;
    }
}

@media (min-width:1600px) {
    .bassket-class {
        height: 414px !important;
    }
    .bassket-class-frais {
        height: 360px !important;
    }
}

@media (min-width:1500px) {
    .bassket-class {
        height: 203px;
    }
    .bassket-class-frais {
        height: 158px;
    }
}

@media (max-width:1024px) {
    .bassket-class {
        .horizontal-line {
            margin-top: 16px !important;
        }

        overflow-x: hidden;
        overflow-y: auto;
    }
    .bassket-class-frais {
        .horizontal-line {
            margin-top: 16px !important;
        }

        overflow-x: hidden;
        overflow-y: auto;
    }
}
/* Target iPhone 12 */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .info-class {
        height: 102px !important;
        overflow-y: auto !important;
    }
}