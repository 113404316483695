.ds-m-auto {
    margin: auto;
}

.border-Bottom {
    border-bottom: 3px solid $neutral100 !important;
    width: -webkit-fill-available !important;
    width: -moz-available;
}

.white-space-nowrap {
    white-space: nowrap;
}

.z-index-1 {
    z-index: -1;
}

.card-position {
    bottom: 10px;
    position: absolute;
    height: 280px;
    padding-top: 60px;
}

.gmnoprint {
    display: none;
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding-top: 90px;
}

.react-multiple-carousel__arrow {
    z-index: 10000000000000000;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: $grayTertiary;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.react-multiple-carousel__arrow--left {
    position: absolute !important;
    top: 0 !important;
    background-color: $white !important;
    left: 2px !important;
    width: 40px;
    height: 40px;
    z-index: 0;
}

.react-multiple-carousel__arrow--right {
    right: 2px !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    background-color: $white !important;
    width: 40px;
    height: 40px;
    z-index: 0;
}

ul {
    li {
        width: 100% !important;
    }
}

.react-multi-carousel-track {
    margin-bottom: 3px !important;
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;

    .carousel img {
        max-width: 100%;
        max-height: 300px;
    }

}

.carousel img {
    max-width: 100%;
    max-height: 300px;
}

.carousel button {
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
}

.icon-shadow {

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.icon-right-absolute-carousel {
    position: absolute;
    top: 414px;
    right: 41px;
}

.icon-left-absolute-carousel {
    position: absolute;
    top: 414px;
    left: 41px;
}

.tab-position-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
}

.text-gradient {
    background: linear-gradient(to left, transparent, #688385);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.size-fixed {
    min-width: 50px !important;
    max-width: 100px !important;
}

.map-style {
    button {
        visibility: hidden;
    }
}

.tab-category {
    width: calc(100% - 382px) !important;
}

@media (max-width:992px) {
    .tab-category {
        width: 100% !important;
    }

    .map-style {
        button {
            visibility: hidden;
        }
    }

    .default-card.type3 .availibility {
        top: 35%;
        border-radius: 0px 8px 8px 0px;
    }

    .tab-category {
        overflow: auto;
        padding: 8px 0px !important;
    }
}

@media (max-width:768px) {

    .card-position {
        padding-top: 0px;
    }
}

@media (max-width:567px) {
    .card-position {
        height: 222px;
    }

    .react-multi-carousel-list {
        padding-top: 0;
    }
}

.ds-p16-0 {
    padding: 0 16px;
}

.star-class {
    color: $primary60 !important;
}

.react-multi-carousel-track>li {
    width: fit-content !important;
}


.react-multi-carousel-track li>div {
    width: fit-content !important;
}

.ds-min-w-40 {
    min-width: 40px;
}

.user-select-none {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    *::-webkit-scrollbar {
        display: none !important;
        width: 1px;
        height: 1px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $transparent;
        border-radius: 20px;
        border: none;
        width: 1px;
    }
}

.ds-fw-700 {
    font-weight: 700;
}

.ds-color-black {
    color: #043033;
}

.overflow-elipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.width-addresse {
    width: calc(100% - 87px);
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}