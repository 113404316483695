.default-card {
    box-sizing: border-box;
    gap: 12px;
    border: 1px solid $neutral10;

    .btn-plus {
        width: 32px;
        height: 32px;
        visibility: hidden;
    }
}

.default-card:hover {
    .btn-plus {
        visibility: visible;
    }

    box-shadow: 0px 4px 6px -1px rgba(40, 16, 32, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.ds-p-0-24 {
    padding: 0 24px;
}

.shadoww {
    box-shadow: 0 4px 6px -1px rgba(16, 24, 40, 0.1) !important;
}

.shadoww-lue {
    box-shadow: 0 4px 6px -1px #1018281A;
}

.default-bow-shadow {
    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
}

.free {
    border-radius: 0px 12.0678px 12.0678px 0px;
    top: 10%;
}

.recharge-class {
    .horizontal-line {
        margin: 0 !important;
    }
}

.bassket-class {
    .horizontal-line {
        margin: 0 !important;
    }
}

.recharge-header-class {
    .horizontal-line {
        margin: 0 0 16px 0 !important;
    }
}

.default-card.type1 {
    width: 512px;
    height: 462px;

    img {
        width: 480px;
        height: 293px;
        flex: 1;
    }

    .free,
    .availibility {
        width: 138px;
        height: 40px;
    }
}

.default-card.type2 {
    width: 314px;
    height: 380px;

    img {
        width: 274px;
        height: 234px;
    }

    .free,
    .availibility {
        width: 108.6px;
        height: 32px;
    }
}

.max-height-100 {
    max-height: 100%;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.my-sites-class {
    .shop-card {
        width: calc(100vw / 4 - 45px);
        min-height: 260px;
        border: 1px solid $white;
        height: auto;
        box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
        justify-content: space-between !important;

        .hover-btn {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

}

.shop-card {
    width: 322px;
    height: 220px;
    border: 1px solid $white;

    img {
        width: 100%;
        height: 100%;
    }

    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);

}

.shop-card-hovered,
.shop-card:hover {
    border: 3px solid $secondary100;

    .hover-btn {
        visibility: visible;
    }

    .shop-btn {
        visibility: visible;
    }
}

.item-card {
    gap: 8px;
    width: calc(100vw / 3 - 370px);
    height: 186px;
    border: 1px solid $neutral4;

    img {
        width: 100%;
        height: 112px;
    }
}

.border-secondary30 {
    left: 75%;
    bottom: 8px;
    height: 24px;
    border-radius: 6px;
    z-index: 1;
    position: absolute;
}

.ds-checkbox:checked {
    background: $secondary20 !important;
    border: 1px solid $secondary20 !important;
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
}

.ds-checkbox:not(.switch)::after {
    opacity: var(--o, 0);
    width: 5px;
    height: 9px;
    border: 2px solid $primary100;
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
}

.default-invoice-card {
    position: relative;
    width: 236px;
    height: 267px;
    border: 1px solid $secondary10;
    box-shadow: 0px 1px 2px rgba(16, 25, 40, 0.05);

    img {
        border-radius: 50%;
        height: 60px;
        width: 60px;
    }
}

.hover-btn {
    width: inherit;
    height: inherit;
    visibility: hidden;
    z-index: 100;
}

.hover-shop {
    position: fixed;
    top: 42px;
}

.align-self-center {
    align-self: center;
}

.shop-btn {
    width: inherit;
    height: inherit;
    visibility: hidden;
    z-index: 100;
    position: absolute;
    top: 0;
}


.default-invoice-card:hover {
    .hover-btn {
        visibility: visible;
        background-color: rgba(51, 51, 51, 0.8);
        border-radius: 8px;
    }

    .shop-btn {
        visibility: visible;
        background-color: rgba(51, 51, 51, 0.8);
        border-radius: 8px;
    }
}

.text-nowrap {
    white-space: nowrap;
}

.text-ellipsis {
    text-overflow: ellipsis;
}


.text-end {
    text-align: end;
}

.gap-20 {
    gap: 20px !important;
}

.gap-5 {
    gap: 5px !important;
}

.small-picture {
    border: 2px solid $white;
    border-radius: 50%;
    left: 42px;
    top: 150px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.box-shadow-50 {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
}

.box-shadow-8 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.line-throught {
    text-decoration-line: line-through;
}

.availibility {
    top: 43%;
    border-radius: 0px 8px 8px 0px;
}

.modal-plan-container {
    .default-card.type3 .availibility {
        top: 44% !important;
        border-radius: 0px 8px 8px 0px;
    }
}


.placeholder-class {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    margin: 0 !important;
    padding-top: 3px;
    font-size: 14px !important;
    color: $primary20 !important;
}

.location-button {
    height: 32px;
    border: 1px solid $primary70 !important;
    padding: 8px !important;
    border-radius: 10px;
    width: 32px;

    svg {
        height: 16px !important;
        width: 16px !important;
        color: $primary70;
    }
}

.icon-location {
    color: $primary70 !important;
}

.search-site-btn {
    color: $primary100;
}

@media (max-width: 320px) {
    .modal-Delivery-data {
        width: 395px !important;
        height: 622px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .ds-p-24 {
            padding: 6px;
        }

        .ds-switch input {
            appearance: none;
            height: 40px !important;
            width: 260px !important;
            border-radius: 12px;
            background-color: #E6EAEB;
            border: 1px solid #E6EAEB;
            outline: none;
        }

        .label-before {
            position: absolute !important;
            top: 9px !important;
            left: 238px !important;
            white-space: nowrap !important;
            z-index: 0 !important;
        }

        .ds-switch input:checked+label {
            left: 178px;
            top: 1.5px;
            background: #FFFFFF;
            transition: left 0.4s right 0.4s 0.4s;
            width: 180px;
            height: 37px;
            border-radius: 12px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 1.5px;
            width: 180px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 12px;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        }
    }

    .recharge-flex-col {
        display: flex;
        flex-direction: column;
        padding-right: 0;

        .image-recharge-class {
            width: 100%;
            height: 150px;
        }

        .site-recharge {
            margin-left: 0;
        }

        .site-class {
            padding-top: 16px;
        }
    }

    .commercant-btn {
        height: 32px;
        width: auto;
        padding: 11px 8px;
        border-radius: 6px;
        min-width: 168px;
    }

    .my-sites-class .shop-card {
        width: calc(100vw - 45px);
        min-height: 206px;
        border: 1px solid #FFFFFF;
        height: auto;

        .hover-btn {
            visibility: hidden;
        }
    }

    .modal-add-note {
        .ds-p-24 {
            padding: 6px;
        }
    }

    .menu-delivery {
        .delivery-mode {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: 240px !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:checked+label {
                left: 119px;
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: 120px;
                height: 37px;
                border-radius: 12px;
            }

            .ds-switch input:not(:checked)+label {
                left: 1px;
                right: 0px;
                width: 120px;
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }

            .text-223 {
                position: absolute;
                right: 40px;
                top: 20px;
            }

            .label-before {
                position: absolute !important;
                top: 4px !important;
                left: 146px !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .label-after {
                position: absolute !important;
                top: 4px !important;
                z-index: 0 !important;
                width: 125px;
            }
        }
    }

    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: 273px !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: 136px;
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: 171px !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: 136px;
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: 136px;
                height: 37px;
                border-radius: 12px;
            }
        }

    }

    .menu-filter:focus {
        padding-left: 71px;
    }

    .default-card.type3 .availibility {
        top: 41%;
        border-radius: 0px 8px 8px 0px;
    }

    .default-basket {
        padding: 16px;
    }

    .menu-filter .ds-px-8 {
        padding-left: 0px !important;
    }

    .basket-overflow {
        padding-right: 8px;
    }

    .menu-search-navbar {
        .input-simple {
            height: 32px !important;
            border-radius: 10px;
            padding: 0 10px 0 24px !important;
        }

        .input-simple::placeholder {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }

        .search-icon {
            top: 2px !important;
            left: 5px !important;
        }
    }



    .search-icon {
        top: 3px !important;
    }

    .menu-filter {
        height: 32px !important;
        min-height: 32px !important;
        width: 100%;
        border-radius: 10px;
    }

    .ds-input-select {
        border: none !important;
        display: inline-block;
        outline: none;
        height: 22px;
        font-size: 14px !important;
        padding-left: 34px;

    }

    .menu-delivery .parimary-70-btn {
        height: 40px !important;
        width: 40px !important;
        border-color: #4F6E70 !important;
        border: 1px solid #4F6E70 !important;
        color: #4F6E70 !important;
        margin-bottom: 10px;
        border-radius: 12px;
    }

    .contact-us-modal {
        width: 500px !important;
        max-height: 621px !important;
        min-width: 300px !important;
        min-height: 300px !important;
        overflow: auto !important;

        .padding-contact {
            padding: 6px !important;

            .horizontal-line {
                margin-top: 16px !important;
            }
        }
    }

    .site-modal {
        .horizontal-line {
            margin: 12px 0px !important;
        }
    }

    .details-product {
        display: flex;
        flex-direction: column;
        padding: 6px;
        width: 100%;
    }

    .product-details-modal {
        width: 328px;
        max-height: 600px;
        min-height: 300px !important;
        overflow: auto !important;

        img {
            width: 269px;
            height: 158px;
        }

        .product-details-modal .ds-switch input {
            height: 40px;
            width: 96px;
            border-radius: 12px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 1.5px;
            bottom: 2px;
            background: #FFFFFF;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            border-radius: 10px;
            width: 49px;
            height: 37px;
        }

        .ds-switch input:checked+label {
            left: 50px;
            top: 1.5px;
            right: -48px;
            height: 37px;
            border-radius: 10px;
            width: 49px;
        }

        .ds-switch input::before {
            content: "Kg";
            right: -44px;
            top: 8px;
        }

        .ds-switch input::after {
            content: "Gr";
            left: 25px;
            top: 8px;
        }

        .ds-switch label {
            top: 2px;
            bottom: 2px;
            border-radius: 14px;
            height: 37px;
        }
    }

    .input-label {
        margin: 0;
        padding-bottom: 8px;
        margin-top: 16px;
        font-size: 12px;
        line-height: 14px;
    }

    .title-planning {
        text-align: center !important;
        padding-bottom: 30px !important;
        margin: 0 !important;
    }

    .shop-times {
        margin: 0;
        height: 320px;
    }

    .modal-Delivery-data {
        .ds-switch input {
            appearance: none;
            height: 40px !important;
            width: 266px !important;
            border-radius: 12px;
            background-color: #E6EAEB;
            border: 1px solid #E6EAEB;
            outline: none;
        }

        .ds-switch input:checked+label {
            left: 129px;
            background: #FFFFFF;
            transition: left 0.4s right 0.4s 0.4s;
            width: 135px;
            height: 37px;
            border-radius: 12px;
        }

        .label-before {
            position: absolute !important;
            top: 9px !important;
            left: 165px !important;
            white-space: nowrap !important;
            z-index: 0 !important;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            width: 135px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 12px;
            transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        }
    }

    .modal-Delivery-data {
        width: 391px;
        height: 651px;
        min-width: 300px !important;
        min-height: 300px !important;
    }

    .delivery-date {
        padding: 6px !important;
    }

    .piece-nbr-class {
        padding-top: 16px;
        font-size: 14px;
        line-height: 16px;
    }

    .compose-note {
        margin-top: 6px;
        margin-bottom: 10px;
    }

    .max-list-height {
        max-height: 87%;
        align-items: center;
        padding-left: 3px;
    }

    .modal-plan-container {
        overflow: hidden;
    }

    .compose-form-class {
        .ds-p-16 {
            padding: 6px !important;
        }
    }

    .compose-min-modal {
        width: 328px !important;
        height: auto !important;
        max-height: auto !important;
        min-width: 309px !important;
        min-height: 440px !important;
        max-width: 328px !important;
    }
}

.select-lang {
    .search-icon {
        padding-left: 10px;
    }

    padding: 0 !important;

    .selected-value {
        font-size: 20px !important;
        font-weight: 500 !important;
        line-height: 24px;
        padding: 12px 8px 12px 3px;
    }
}

.modal-Bon-plan .default-card.type3 {
    height: 280px !important;

    .text-overflow-card {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    .options-delivery {
        margin: 8px 0 12px 0 !important;
    }
}

@media screen and (min-width: 320px) and (max-width:399px) {
    .my-sites-class .shop-card {
        width: calc(100vw - 45px);
        min-height: 206px;
        border: 1px solid #FFFFFF;
        height: auto;
        justify-content: space-between !important;

        .hover-btn {
            visibility: hidden;
        }

        // .hover-btn {
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
    }
}

@media screen and (min-width: 400px) and (max-width:480px) {
    .my-sites-class .shop-card {
        width: calc(100vw - 45px);
        min-height: 240px;
        border: 1px solid #FFFFFF;
        height: auto;
        justify-content: space-between !important;

        .hover-btn {
            visibility: hidden;
        }

        // .hover-btn {
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
    }
}

@media screen and (min-width: 481px) and (max-width: 799px) {
    .my-sites-class .shop-card {
        width: calc(100vw / 2 - 45px);
        min-height: 206px;
        border: 1px solid #FFFFFF;
        height: auto;

        .hover-btn {
            visibility: hidden;
        }
    }
}

@media screen and (min-width: 800px) and (max-width: 899px) {
    .my-sites-class .shop-card {
        width: calc(100vw / 2 - 45px);
        min-height: 260px;
        border: 1px solid #FFFFFF;
        height: auto;
        padding-bottom: 40px;

        .hover-btn {
            visibility: hidden;
        }
    }
}

@media screen and (min-width: 993px) and (max-width: 1100px) {
    .my-sites-class .shop-card {
        width: calc(100vw / 3 - 51px) !important;
        min-height: 240px;
        border: 1px solid #FFFFFF;
        height: auto;

        .hover-btn {
            visibility: hidden;
        }

        img {
            width: 100% !important;
        }
    }
}

@media screen and (min-width: 900px) and (max-width: 992px) {

    .my-sites-class .shop-card {
        width: calc(100vw / 3 - 35px);
        min-height: 230px;
        border: 1px solid #FFFFFF;
        height: auto;

        .hover-btn {
            visibility: hidden;
        }

        img {
            width: 100% !important;
        }
    }
}

@media screen and (min-width: 900px) and (max-width: 992px) {
    .select-lang {
        text-align: center;
        align-items: center;

        .ds-px-8 {
            padding-left: 5px !important;
        }

        .ds-mr-8 {
            margin-right: 0 !important;
        }

        .selected-value {
            padding: 0 0 0 3px !important;
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .select-drop-down-not-logged {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

}

@media screen and (min-width: 1600px) {
    .item-card {
        gap: 8px;
        width: 152px;
        height: 186px;
        border: 1px solid #F7F7F7;
    }

    .modal-compose-formule {
        width: 1000px !important;
        height: 736px !important;
        min-width: 1000px !important;
        min-height: 704px !important;

        .ds-mb-32 {
            margin-bottom: 25px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .compose-min-modal {
        width: 328px !important;
        height: auto !important;
        max-height: auto !important;
        min-width: 309px !important;
        min-height: 440px !important;
        max-width: 328px !important;
    }


}

.nbr-pt-24 {
    padding-top: 24px;
}

@media screen and (min-width: 1101px) and (max-width: 1600px) {
    .my-sites-class .shop-card {
        width: calc(100vw / 4 - 46px);
        min-height: 206px;
        border: 1px solid #FFFFFF;
        height: auto;

        img {
            width: 100% !important;
        }
    }
}

@media ((min-width: 320px) and (max-width: 900px)) {

    .select-lang {
        text-align: center;
        align-items: center;

        .ds-px-8 {
            padding-left: 5px !important;
        }

        .ds-mr-8 {
            margin-right: 0 !important;
        }

        .selected-value {
            padding: 0 0 0 3px !important;
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .recharge-flex-col {
        display: flex;
        flex-direction: column;
        padding-right: 0;

        .image-recharge-class {
            width: 100%;
            height: 150px;
        }

        .site-recharge {
            margin-left: 0;
        }

        .site-class {
            padding-top: 16px;
        }
    }

    .commercant-btn {
        height: 32px;
        width: auto;
        padding: 11px 8px;
        border-radius: 6px;
        min-width: 168px;
    }

    .modal-add-note {
        .ds-p-24 {
            padding: 6px;
        }
    }

    .options-delivery {
        margin: 8px 0 12px 0 !important;
    }

    .menu-search-navbar {
        .input-simple {
            height: 32px !important;
            border-radius: 10px;
            padding: 0 10px 0 24px !important;
        }

        .input-simple::placeholder {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }

        .search-icon {
            svg {
                height: 16px !important;
                width: 16px !important;
            }

            top: 2px !important;
            left: 5px !important;
        }
    }

    .menu-filter:focus {
        padding-left: 71px !important;
    }

    .menu-filter .ds-px-8 {
        padding-left: 0px !important;
    }

    .menu-filter {
        height: 32px !important;
        min-height: 32px !important;
        width: 100%;
        border-radius: 10px;
    }

    .details-product {
        display: flex;
        flex-direction: column;
        padding: 6px;
        width: 100%;
    }

    .product-details-modal {
        width: 328px;
        max-height: 628px;
        min-height: 300px !important;
        overflow: auto !important;
        padding: 6px !important;
        height: max-content;

        .comment-mt-24 {
            margin-top: 16px !important;
        }

        .comment-pt-24 {
            margin-top: 0 !important;
        }

        .nbr-pt-24 {
            padding-top: 16px;
        }

        .description-mt-8 {
            margin-top: 8px !important;
        }

        .comment-pb-24 {
            padding-bottom: 16px !important;
        }
    }

    .product-details-modal {
        .product-quantity {
            .input-kg {
                padding: 0px !important;
            }
        }

        .ds-switch input::after {
            content: "Gr";
            left: 24px;
            top: 7px;
        }

        .ds-switch input::before {
            content: "Kg";
            right: -42px;
            top: 7px;
        }
    }


    .decription-size {
        font-size: 14px !important;
        line-height: 16px;
    }

    .product-details-modal .quatity-input {
        height: 40px !important;
        min-width: 66px;
        padding: 0px;

        input {
            text-align: center;
        }
    }

    .product-quantity .ds-pl-16 {
        padding: 0px !important;
    }

    .product-details-modal {
        .ds-switch input:checked+label {
            left: 49px;
            right: -48px;
            top: 1.5px;
            width: 49px;
            height: 37px;
        }

        .ds-switch input:not(:checked)+label {
            left: 2px;
            right: 0px;
            top: 1.5px;
            width: 49px;
            height: 37px;
            background: #FFFFFF;
            border-radius: 10px;
        }

    }

    .menu-delivery .parimary-70-btn {
        height: 40px !important;
        width: 40px !important;
        border-color: #4F6E70 !important;
        border: 1px solid #4F6E70 !important;
        color: #4F6E70 !important;
        margin-bottom: 10px;
        border-radius: 12px;
    }

    .compose-min-modal img {
        width: 100%;
        height: 204px;
    }

    .compose-min-modal {
        width: 328px !important;
        height: auto !important;
        max-height: auto !important;
        min-width: 309px !important;
        min-height: 440px !important;
        max-width: 328px !important;
    }

    .max-list-height {
        max-height: 87%;
        align-items: center;
        padding-left: 3px;
    }

    .modal-plan-container {
        overflow: hidden;

        .default-card.type3 .availibility {
            top: 44% !important;
            border-radius: 0px 8px 8px 0px;
        }
    }

    .compose-form-class {
        padding: 6px;
    }

    .title-planning {
        text-align: center !important;
        padding-bottom: 30px !important;
        margin: 0 !important;
    }

    .shop-times {
        margin: 0;
        height: 300px;
    }

    .default-card.type3 .availibility {
        top: 52% !important;
        border-radius: 0px 8px 8px 0px;
    }
}

@media ((min-width:901px) and (max-width: 1200px)) {
    .contact-us-modal {
        width: 400px !important;
        max-height: 600px !important;
        min-width: 300px !important;
        min-height: 300px !important;
        overflow: auto !important;

        .ds-px-40 {
            padding: 16px;
        }
    }
}

@media ((min-width:501px) and (max-width: 515px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 148px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 148px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 125px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 150px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 148px) / 2);
                height: 37px;
                border-radius: 12px;
            }
        }

    }
}

@media ((min-width:481px) and (max-width: 500px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 136px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 136px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 127px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 138px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 136px) / 2);
                height: 37px;
                border-radius: 12px;
            }
        }

    }
}

@media ((min-width:477px) and (max-width: 480px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 117px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 117px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 110px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 119px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 117px) / 2);
                height: 37px;
                border-radius: 12px;
            }
        }

    }
}

@media ((min-width:456px) and (max-width: 476px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 103px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 103px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px !important;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 125px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 105px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 103px) / 2);
                height: 37px;
                border-radius: 12px;
                top: 1.5px !important;
            }
        }

    }
}

@media ((min-width:443px) and (max-width: 455px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 97px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 97px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px !important;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 125px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 99px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 97px) / 2);
                height: 37px;
                border-radius: 12px;
                top: 1.5px !important;
            }
        }

    }
}

@media ((min-width:431px) and (max-width: 442px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 80px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 80px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px !important;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 125px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 82px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 80px) / 2);
                height: 37px;
                border-radius: 12px;
                top: 1.5px !important;
            }
        }

    }
}

@media ((min-width:418px) and (max-width: 430px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 637px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 70px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 70px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px !important;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 110px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 72px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 70px) / 2);
                height: 37px;
                border-radius: 12px;
                top: 1.5px !important;
            }
        }

    }
}

@media ((min-width:320px) and (max-width: 417px)) {
    .modal-Delivery-data {
        width: 395px;
        height: 622px !important;
        min-width: 300px !important;
        min-height: 300px !important;

        .delivery-date {
            .ds-switch input {
                appearance: none;
                height: 40px !important;
                width: calc(100vw - 52px) !important;
                border-radius: 12px;
                background-color: #E6EAEB;
                border: 1px solid #E6EAEB;
                outline: none;
            }

            .ds-switch input:not(:checked)+label {
                left: 2px;
                right: 0px;
                width: calc((100vw - 52px) / 2);
                height: 37px;
                background: #FFFFFF;
                border-radius: 12px;
                transition: left 0.4s 0.2s right 0.4s, background 0.4s;
                top: 1.5px !important;
            }

            .label-before {
                position: absolute !important;
                top: 9px !important;
                left: calc(100% - 116px) !important;
                white-space: nowrap !important;
                z-index: 0 !important;
            }

            .label-after {
                position: absolute !important;
                z-index: 0 !important;
                width: 140px;
            }

            .ds-switch input:checked+label {
                left: calc((100vw - 54px) / 2);
                background: #FFFFFF;
                transition: left 0.4s right 0.4s 0.4s;
                width: calc((100vw - 52px) / 2);
                height: 37px;
                border-radius: 12px;
                top: 1.5px !important;
            }
        }

    }
}

.border-none {
    width: 65px;

    .search-icon {
        top: 10px !important;
    }

    padding: 0 !important;

    .padding-multi-select {
        padding-left: 8px;
    }
}

.img-class-1 {
    width: 387px;
    height: 302px;
}

.img-class-2 {
    width: 387px;
    height: 312px;
}

.lang-select-sidebar {
    width: 40px !important;

    .select-options {
        font-size: 13px !important;
        font-weight: 500 !important;
        line-height: 16px;
    }
}

@media (min-width:1401px) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1301px) and (max-width: 1400px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1301px) and (max-width: 2000px)) {
    .product-name-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

@media ((min-width:1251px) and (max-width: 1300px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1201px) and (max-width: 1250px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1201px) and (max-width: 1250px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1171px) and (max-width: 1200px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1136px) and (max-width: 1170px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1101px) and (max-width: 1135px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }
}

@media ((min-width:1025px) and (max-width: 1100px)) {
    .lang-select-sidebar {
        width: 50px !important;
    }

}

@media ((min-width:993px) and (max-width: 1024px)) {
    .lang-select-sidebar {
        width: 40px !important;
    }
}

@media ((min-width:901px) and (max-width: 992px)) {
    .card-list .default-card.type3 .availibility {
        top: 39% !important;
        border-radius: 0px 8px 8px 0px;
    }
}

@media ((min-width:360px) and (max-width: 900px)) {
    .card-list .default-card.type3 .availibility {
        top: 42% !important;
        border-radius: 0px 8px 8px 0px;
    }
}

@media ((min-width:360px) and (max-width: 900px)) {
    .product-img {
        width: 100%;
        background-size: contain !important;
    }

    .desc-class {
        padding-bottom: 16px;
    }

    .quatity-class {
        padding-bottom: 6px;
    }

    .modal-Delivery-data {
        width: 395px !important;
        height: 630px !important;
        min-width: 300px !important;
        min-height: 300px !important;
    }

    .contact-us-modal {
        width: 400px !important;
        max-height: 600px !important;
        min-width: 300px !important;
        min-height: 300px !important;
        overflow: auto !important;

        .ds-px-40 {
            padding: 6px;
        }
    }

    .select-drop-down {
        .padding-multi-select {
            padding-left: 8px !important;
        }

        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .select-lang {
        text-align: center;
        align-items: center;

        .ds-px-8 {
            padding-left: 5px !important;
        }

        .ds-mr-8 {
            margin-right: 0 !important;
        }

        .selected-value {
            padding: 0 0 0 3px !important;
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .select-drop-down-not-logged {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .select-drop-down {
        .select-options {
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 16px;
        }
    }

    .recharge-flex-col {
        display: flex;
        flex-direction: column;
        padding-right: 0;

        .image-recharge-class {
            width: 100%;
            height: 150px;
        }

        .site-recharge {
            margin-left: 0;
        }

        .site-class {
            padding-top: 16px;
        }
    }

    .commercant-btn {
        height: 32px;
        width: auto;
        padding: 11px 8px;
        border-radius: 6px;
        min-width: 168px;
    }

    .placeholder-class {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }

    .item-card {
        gap: 8px;
        width: calc(100vw / 2 - 37px);
        height: 186px;
        border: 1px solid #F7F7F7;
        margin: 0px 8px 8px 0px;

        img {
            width: 100%;
            height: 107px;
        }
    }

    .border-secondary30 {
        left: 97px;
        bottom: 8px;
        height: 24px;
        border-radius: 6px;
        z-index: 1;
        position: absolute;
    }

    .compose-form-class {
        .horizontal-line {
            margin: 0px !important;
        }
    }

    .header-sidebar {
        .horizontal-line {
            margin: 24px 0px !important;
        }
    }
}

.gradient-text {
    background: linear-gradient(45deg, #c7cecf, #f7f8f7);
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(104, 131, 133, 0.7);
    display: inline-block;
    /* Ensure the gradient is applied to the text */
}

.image-product {
    width: 492px;
    height: 492px;
    margin-right: 32px;
}

@media only screen and (min-device-width: 24in) {
    .product-img {
        width: 100%;
        background-size: contain !important;
    }

}

@media (min-width:1600px) and (max-width:2000px) {
    .product-img {
        width: 100%;
    }


}

/* Target iPhone 7 plus */
@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
    .card-list .default-card.type3 {
        width: calc(100vw / 2 - 27px) !important;
    }

    .city-class .ds-text-line-28 {
        line-height: 24px !important;
    }

    .city-class .ds-text-size-24 {
        font-size: 20px;
    }

    .city-class .ds-text-line-43 {
        line-height: 33.6px !important;
    }

    .city-class .ds-text-size-36 {
        font-size: 25px;
    }
}

/* Target iPhone 12 */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .card-list .default-card.type3 {
        width: calc(100vw / 2 - 27px) !important;
    }

    .city-class .ds-text-line-28 {
        line-height: 24px !important;
    }

    .city-class .ds-text-size-24 {
        font-size: 20px;
    }

    .city-class .ds-text-line-43 {
        line-height: 33.6px !important;
    }

    .city-class .ds-text-size-36 {
        font-size: 25px;
    }

    .modal-Delivery-data .delivery-date .ds-switch input {
        width: calc(100vw - 52px) !important
    }

    .modal-Delivery-data .delivery-date .ds-switch input:checked+label {
        width: calc((100vw - 73px)/2) !important
    }

    .product-details-modal {
        height: max-content;
        max-height: 628px;
        min-height: 300px !important;
        padding: 12px 8px !important;
        padding-bottom: 18px !important;
    }

    .desc-class {
        padding-bottom: 10px;
    }

    .product-details-modal {
        .ds-switch input:checked+label {
            left: 49px;
        }
    }


}

@media (max-width:1024px) {
    .image-product {
        width: 305px;
        height: 204px;
        margin-right: 0px;
    }

    .text-overflow-card {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    .product-name-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

@media (max-width:992px) {
    .tab-element-class {
        padding: 0 8px;
    }

    .tab-element-class:first-child {
        padding-left: 0px;
    }

    .tab-element-class:last-child {
        padding-right: 0px;
    }

    .modal-Delivery-data {
        width: 395px !important;
        height: 645px !important;
        min-width: 300px !important;
        min-height: 300px !important;
    }

    .default-card .btn-plus {
        width: 24px;
        height: 24px;
        visibility: visible;

        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }

    .product-name-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

@media (max-width:768px) {
    .modal-Delivery-data {
        height: 620px !important;
    }
}

@media ((min-width: 1024px) and (max-width:1300px)) {
    .modal-Delivery-data {
        width: 395px !important;
        height: 708px !important;
        min-width: 300px !important;
        min-height: 300px !important;
    }

    .item-card {
        gap: 8px;
        width: 148px;
        height: 186px;
        border: 1px solid $neutral4;

        img {
            width: 100%;
            height: 112px;
        }
    }

    .border-secondary30 {
        left: 60%;
        bottom: 8px;
        height: 24px;
        border-radius: 6px;
        z-index: 1;
        position: absolute;
    }

    .product-name-ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.my-carousel-arrow {
    position: absolute;
    outline: 0;
    transition: all 0.5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
}

.my-carousel-arrow::before {
    font-size: 20px;
    color: #4E4E4E;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
}

.my-carousel-arrow-right {
    right: 2px !important;
    top: 0 !important;
    bottom: 0 !important;
    background-color: #FFFFFF !important;
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.my-carousel-arrow-right::before {
    content: "\e825";
}

.my-carousel-arrow-left {
    left: 2px !important;
    top: 0 !important;
    bottom: 0 !important;
    background-color: #FFFFFF !important;
    width: 40px;
    height: 40px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.24);
}

.my-carousel-arrow-left::before {
    content: "\e824";
}

.card-list .default-card.type3 {
    width: calc(100vw / 5 - 29px);
    height: 326px;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    .free,
    .availibility {
        width: 73px;
        height: 27.67px;
        font-size: 10px !important;
        top: 39% !important;
        border-radius: 0px 8px 8px 0px;
    }

    .ds-p-16 {
        padding: 8px !important;
    }

    @media (max-width: 320px) {
        width: calc(100vw - 34px);
        height: 306px;
    }

    @media ((min-width: 320px) and (max-width: 480px)) {
        width: calc(100vw / 2 - 32px);
    }

    @media ((min-width: 480px) and (max-width: 767px)) {
        width: calc(100vw / 3 - 25px);
    }

    @media ((min-width: 767px) and (max-width: 992px)) {
        width: calc(100vw / 4 - 22px) !important;
    }

    @media ((min-width: 992px) and (max-width: 1024px)) {
        width: calc(100vw / 4 - 34px) !important;
    }

    @media ((min-width: 1600px) and (max-width: 1860px)) {
        width: calc(100vw / 6 - 24px) !important;
    }

    @media ((min-width: 1860px)) {
        width: calc(100vw / 7 - 24px);
    }

    @media ((min-width: 2240px)) {
        width: calc(100vw / 10 - 20px);
    }
}

.my-sites-class .shop-card {
    @media (max-width: 460px) {
        width: calc(100vw - 54px);
    }

    @media ((min-width: 460px) and (max-width: 720px)) {
        width: calc(100vw / 2 - 48px);
    }

    @media ((min-width: 720px) and (max-width: 992px)) {
        width: calc(100vw / 3 - 38px);
    }

    @media ((min-width: 992px) and (max-width: 1024px)) {
        width: calc(100vw / 4 - 48px) !important;
    }

    @media ((min-width: 1024px) and (max-width: 1520px)) {
        width: calc(100vw / 5 - 42px) !important;
    }

    @media (min-width: 1520px) {
        width: calc(100vw / 6 - 40px)
    }

    @media ((min-width: 2120px)) {
        width: calc(100vw / 8);
    }

    @media ((min-width: 2440px)) {
        width: calc(100vw / 10 - 8px);
    }
}