.ds-loader.primary {
  border: 2.5px solid $white;
  border-bottom-color: $transparent;
}

.ds-loader.secondary {
  border: 2.5px solid $primary;
  border-bottom-color: $transparent;
}

.ds-loader {
  border-bottom-color: $transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}