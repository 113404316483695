.default-sidebar {
    width: 300px;
    box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1);
}

.width-300 {
    width: 300px !important;
}

.horizontal-line {
    border: 1px solid $primary10;
}

.grey-line {
    border: 1px solid $primary10;
}

.horizontal-cmd-line {
    border: 1px solid $primary10;
}

.horizontal-line-100 {
    border: 1px solid $primary100;
}

.border-none {
    border: none;
}

.bottom {
    bottom: 0;
}

.left {
    left: 0;
}

.underlined-Text {
    text-decoration: underline;
}

.left-50 {
    left: 0%;
}

.right {
    right: 0;
}

.float-right {
    float: right;
}

.border-radius-50 {
    border-radius: 50%;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.border-right {
    border-right: 1px solid $neutral100;
}

.border-left {
    border-left: 3px solid $secondary100;
}

.top-10 {
    top: 10;
}

.border-left-primary {
    border-left: 1px solid $primary;
    border-radius: 30%;
}

.side-bar-border {
    border: 1px solid $neutral100;
}

.text-overflow-sidebar {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-overflow-card {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.selected-left-border {
    position: absolute;
    width: 4px;
    height: 16px;
    left: 0px;
    top: 8px;
    background: $primary;
    border-radius: 3px;
}

.path-text {
    color: $primary70;
    text-decoration: none;
}

.path-text:hover {
    color: $primary100;
    text-decoration: none;
}

.path-text:focus {
    color: $primary100;
    text-decoration: none;
    background-color: $secondary10
}

@media (max-width: 1024px) {
    .default-sidebar {
        width: 100%;
        position: absolute;
        z-index: 100;
    }

    .outlet-container {
        background-color: rgba(51, 51, 51, 0.8);
        backdrop-filter: blur(12px);
        height: 100vh;
        width: 100%;
    }

    .navbar-container {
        opacity: 0;
    }

    .gap-32 {
        gap: 10px;
    }
}


.sidebar-container {
    overflow: hidden;
}

.sidebar {
    width: 300px;
}

.content {
    flex-grow: 1;
    background-color: rgba(51, 51, 51, 0.8);
    backdrop-filter: blur(12px);
}

.EZDrawer {
    input {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 768px) {
    .sidebar-container {
        flex-direction: column;
        overflow: hidden;
    }

    .sidebar {
        width: 70%;
    }

    .content {
        flex-grow: 1;
        background-color: rgba(51, 51, 51, 0.8);
        backdrop-filter: blur(12px);
        width: 100%;
    }

    .width-300 {
        width: 100% !important;
        padding: 16px;
    }
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay {
    flex-grow: 1;
    background-color: rgba(51, 51, 51, 0.8) !important;
    backdrop-filter: blur(12px) !important;
    opacity: 1 !important;
    cursor: pointer;
}

.infinite-scroll-component {
    scrollbar-width: none;
}