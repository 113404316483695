.react-tel-input .phone-input {

    width: 100% !important;
    height: 48px !important;
    background: $white !important;
    border: 1px solid $primary70;
    border-radius: 8px !important;

}

.inValid,
.not-valid .react-tel-input .phone-input,
.not-valid .flag-dropdown {
    border: 1px solid $error100 !important;
}

.not-valid .react-tel-input .flag-dropdown {
    border-color: $error100 !important;
}

.phone-input-edit {

    width: 100% !important;
    height: 40px !important;
    background: $white !important;
    border: 1px solid $primary20 !important;
    border-radius: 8px !important;

}

.react-tel-input .flag-dropdown {
    border-right: 0px !important;
    background-color: $white !important;
    border-radius: 8px 0px 0px 8px !important;
    border-color: $primary70 !important;

}

.react-tel-input .form-control:focus {
    box-shadow: none !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
}