.ds-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    vertical-align: top;
    position: relative;
    cursor: pointer;
}

.ds-checkbox::after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}

.ds-checkbox:checked {
    background: $secondary;
    border: 1px solid $secondary;
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
}

.ds-checkbox.checkbox.invalid:checked {
    background: $error100;
    border: 1px solid $error100;
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
}

.ds-checkbox:not(.switch)::after {
    opacity: var(--o, 0);
    width: 5px;
    height: 9px;
    border: 2px solid $white;
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
}

.ds-checkbox:not(.switch):checked {
    --o: 1;
    --r: 43deg;
}

.ds-checkbox:not(.switch):not(:checked) {
    border: 1px solid $neutral100;
}

.ds-checkbox.invalid:not(.switch):not(:checked) {
    border: 1px solid $error70;
}

.ds-checkbox.valid:not(.ds-switch):not(:checked) {
    border: 1px solid $success100;
}

.ds-checkbox.switch::after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, $neutral100);
    transform: translateX(var(--x, 0));
}

.ds-checkbox.switch:checked {
    --ab: #fff;
    --x: 17px;
}

.ds-checkbox.switch:not(:checked) {
    --ab: #fff;
    background: $neutral100;
    border: 1px solid $white;
}

.ds-checkbox:disabled:checked {
    background: $neutral10;
    border: 1px solid $white;
}

.ds-checkbox:disabled:not(:checked) {
    background: $white;
    border: 1px solid $neutral10;
}

.ds-checkbox.switch.invalid,
.ds-checkbox.checkbox.invalid {
    border: 2px solid $error100;
    background: $error100;
}

.ds-checkbox.switch.valid,
.ds-checkbox.checkbox.valid {
    border: 2px solid $success100;
    background: $success100;
}

.ds-switch {
    position: relative;
    height: 50px;
}

.ds-switch input {
    appearance: none;
    height: 50px;
    width: 312px;
    border-radius: 16px;
    background-color: $primary10;
    border: 1px solid $primary10;
    outline: none;
}

.ds-switch input::before,
.ds-switch input::after {
    z-index: 2;
    position: absolute;
    top: 56%;
    transform: translate(-50%);
    font-weight: bolder;
}

.ds-switch input::after {
    left: 73px;
    top: 6px;
}

.ds-switch input::before {
    right: -153px;
    top: 6px;
}

.ds-switch input:checked {
    background: $primary10;
}

.ds-switch input {
    transition: 0.25s;
}

.ds-switch label {
    position: absolute;
    top: 10px;
    bottom: 4px;
    border-radius: 14px;
    box-shadow: 0px 1px 2px rgba(16, 25, 40, 0.05);
}

.ds-switch input:checked::before {
    color: $primary100;
    transition: 0.4s;
}

.ds-switch input:checked::after {
    color: $primary50;
    transition: 0.4s;
}

.text-223 {
    position: absolute;
    right: -79px;
    top: 31px;
}

.text-224 {
    position: absolute;
    left: 49px;
    top: 31px;
}

.ds-switch input:checked+label {
    left: 153px;
    right: -156px;
    top: 2px;
    bottom: 2px;
    background: $white;
    transition: left 0.4s right 0.4s 0.4s;
}

.ds-switch input:not(:checked)::before {
    color: $primary50;
    transition: color 0.4s;
}

.ds-switch input:not(:checked)::after {
    color: $primary100;
    transition: left 0.4s 0.2s right 0.5s, background 0.35s;
}

.ds-switch input:not(:checked)+label {
    left: 2px;
    right: 0px;
    top: 2px;
    bottom: 2px;
    background: $white;
    transition: left 0.4s 0.2s right 0.4s, background 0.4s;
}

.delivery-mode {
    .ds-switch input {
        appearance: none;
        height: 48px !important;
        width: 250px !important;
        border-radius: 16px;
        background-color: #E6EAEB;
        border: 1px solid #E6EAEB;
        outline: none;
    }

    .ds-switch input:checked+label {
        left: 126px;
        background: #FFFFFF;
        transition: left 0.4s right 0.4s 0.4s;
        width: 122px;
        height: 44px;
        top: 1.82px;
        border-radius: 14px;
    }

    .ds-switch input:not(:checked)+label {
        left: 2px;
        right: 0px;
        width: 122px;
        height: 44px;
        top: 1.82px;
        background: #FFFFFF;
        transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        border-radius: 14px;
    }

    .ds-switch input::before {
        right: -117px;
        top: 6px;
    }

    .text-223 {
        position: absolute;
        right: 48px;
        top: 25px;
    }

    .text-224 {
        position: absolute;
        left: 48px;
        top: 25px;
    }
}

.delivery-checkbox {
    .ds-switch input {
        appearance: none;
        height: 40px !important;
        width: 323px !important;
        border-radius: 16px;
        background-color: #E6EAEB;
        border: 1px solid #E6EAEB;
        outline: none;
    }

    .ds-switch input:not(:checked)+label {
        left: 2px;
        right: 0px;
        width: 154px;
        height: 37px;
        background: #FFFFFF;
        transition: left 0.4s 0.2s right 0.4s, background 0.4s;
        top: 1.25px;
    }

    .ds-switch input:checked+label {
        left: 158px;
        background: #FFFFFF;
        transition: left 0.4s right 0.4s 0.4s;
        width: 154px;
        height: 37px;
        top: 1.25px;
    }

    .ds-switch input::before {
        right: -160px;
        top: 6px;
    }
}

.checkbox-recharge {
    .ds-checkbox:checked {
        background: $primary100 !important;
        border: 1px solid $primary100 !important;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
}

.general-condition-checkbox {
    .ds-checkbox:not(.switch):not(:checked) {
        border: 1px solid $primary100 !important;
    }

    .ds-checkbox:checked {
        background: $secondary10 !important;
        border: 1px solid $secondary10 !important;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
        border-radius: 4px;
    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $primary100;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
        border-radius: 4px;
    }
}

.supplement-checkbox {
    .ds-checkbox:checked {
        background: $primary100 !important;
        border: 1px solid $primary100 !important;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
        border-radius: 4px;
    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
        border-radius: 4px;
    }

    label {
        color: $primary100 !important;
    }
}

.card-saved-checkbox {
    .ds-checkbox:not(.switch):not(:checked) {
        border: 1px solid $primary40;
    }

    .ds-checkbox:checked {
        background: $primary100 !important;
        border: 1px solid $primary100 !important;
        --d-o: 0.3s;
        --d-t: 0.6s;
        --d-t-e: cubic-bezier(0.2, 0.45, 0.32, 1.2);
    }

    .ds-checkbox:not(.switch)::after {
        opacity: var(--o, 0);
        width: 5px;
        height: 9px;
        border: 2px solid $white;
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
    }
}

.label-after {
    position: absolute;
    top: 6px;
    z-index: 0;
    width: 125px;
}

.label-before {
    position: absolute;
    top: 6px;
    left: 152px;
    white-space: nowrap;
    z-index: 0;
}

.checked-class {
    span {
        display: inline;
        height: 17px;
    }
}

@media (max-width:992px) {
    .label-before {
        position: absolute;
        top: 4px;
        left: 174px;
        white-space: nowrap;
        z-index: 0;
    }

    .label-after {
        position: absolute;
        top: 4px;
        z-index: 0;
        width: 125px;
    }
}