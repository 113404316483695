.ds-row {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 15px);
}

.ds-col {
    width: auto;
}

.ds-col-1 {
    width: calc(1 * 8.3333333333333%);
}

.ds-col-2 {
    width: calc(2 * 8.3333333333333%);
}

.ds-col-3 {
    width: calc(3 * 8.3333333333333%);
}

.ds-col-4 {
    width: calc(4 * 8.3333333333333%);
}

.ds-col-5 {
    width: calc(5 * 8.3333333333333%);
}

.ds-col-6 {
    width: calc(6 * 8.3333333333333%);
}

.ds-col-7 {
    width: calc(8 * 8.3333333333333%);
}

.ds-col-8 {
    width: calc(9 * 8.3333333333333%);
}

.ds-col-10 {
    width: calc(10 * 8.3333333333333%);
}

.ds-col-11 {
    width: calc(11 * 8.3333333333333%);
}

.ds-col-12 {
    width: calc(12 * 8.3333333333333%);
}

@media (max-width:576px) {
    .ds-col-xs-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-xs-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-xs-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-xs-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-xs-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-xs-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-xs-7 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-xs-8 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-xs-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-xs-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-xs-12 {
        width: calc(12 * 8.3333333333333%);
    }
}

@media (min-width:576px) {
    .ds-col-sm-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-sm-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-sm-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-sm-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-sm-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-sm-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-sm-7 {
        width: calc(7 * 8.3333333333333%);
    }

    .ds-col-sm-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-sm-9 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-sm-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-sm-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-sm-12 {
        width: calc(12 * 8.3333333333333%);
    }
}

@media (min-width:768px) {
    .ds-col-md-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-md-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-md-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-md-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-md-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-md-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-md-7 {
        width: calc(7 * 8.3333333333333%);
    }

    .ds-col-md-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-md-9 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-md-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-md-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-md-12 {
        width: calc(12 * 8.3333333333333%);
    }
}

@media (min-width:992px) {
    .ds-col-lg-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-lg-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-lg-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-lg-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-lg-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-lg-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-lg-7 {
        width: calc(7 * 8.3333333333333%);
    }

    .ds-col-lg-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-lg-9 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-lg-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-lg-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-lg-12 {
        width: calc(12 * 8.3333333333333%);
    }
}

@media (min-width:1200px) {
    .ds-col-xl-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-xl-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-xl-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-xl-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-xl-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-xl-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-xl-7 {
        width: calc(7 * 8.3333333333333%);
    }

    .ds-col-xl-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-xl-9 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-xl-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-xl-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-xl-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-xl-12 {
        width: calc(12 * 8.3333333333333%);
    }
}

@media (min-width:1400px) {
    .ds-col-xxl-1 {
        width: calc(1 * 8.3333333333333%);
    }

    .ds-col-xxl-2 {
        width: calc(2 * 8.3333333333333%);
    }

    .ds-col-xxl-3 {
        width: calc(3 * 8.3333333333333%);
    }

    .ds-col-xxl-4 {
        width: calc(4 * 8.3333333333333%);
    }

    .ds-col-xxl-5 {
        width: calc(5 * 8.3333333333333%);
    }

    .ds-col-xxl-6 {
        width: calc(6 * 8.3333333333333%);
    }

    .ds-col-xxl-7 {
        width: calc(7 * 8.3333333333333%);
    }

    .ds-col-xxl-8 {
        width: calc(8 * 8.3333333333333%);
    }

    .ds-col-xxl-9 {
        width: calc(9 * 8.3333333333333%);
    }

    .ds-col-xxl-10 {
        width: calc(10 * 8.3333333333333%);
    }

    .ds-col-xxl-11 {
        width: calc(11 * 8.3333333333333%);
    }

    .ds-col-xxl-12 {
        width: calc(12 * 8.3333333333333%);
    }
}