@media only screen and (min-width: 768px) {
    .default-text.type-1 {
        font-size: 96px;
        line-height: 112px
    }

    .default-text.type-2 {
        font-size: 60px;
        line-height: 72px
    }

    .default-text.type-3 {
        font-size: 48px;
        line-height: 56px
    }

    .default-text.type-4 {
        font-size: 34px;
        line-height: 42px
    }

    .default-text.type-5 {
        font-size: 24px;
        line-height: 32px
    }

    .default-text.type-6 {
        font-size: 20px;
        line-height: 32px
    }
}
.default-text{
    p{
        margin: 0 !important;
    }
}
.text-transparent{
    color: transparent;
    user-select: none;
}