.default-input-file {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    font-size: 16px !important;
}

.default-input-file.type1 {
    border: 1px solid $neutral100;
    border-radius: 8px;
    align-items: center;
    height: 40px;
    width: 200px;
    padding: 0px 15px;
    font-size: 16px !important;
}

.default-input-file.type2 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px dotted $neutral100;
    font-size: 16px !important;

}

.default-input-file:hover,
.default-input-file.type1:focus,
.default-input-file.type2:focus {
    color: $primary;
    border: 1px solid $primary;
    font-size: 16px !important;

}
.default-input-file.ds-op-04:hover,
.default-input-file.type1.ds-op-04:focus,
.default-input-file.type2.ds-op-04:focus {
    color: $dark;
    border: 1px solid $neutral100;
    font-size: 16px !important;

}

.default-input-file input[type="file"] {
    opacity: 0;
    display: none;
}

.default-input-file::placeholder {
    color: $neutral100;
    font-size: 16px !important;

}

.default-input-file:disabled {
    cursor: not-allowed;
    font-size: 16px !important;

}

.upload-file:hover,
.upload-file:focus {
    background-color: $neutral100;
    font-size: 16px !important;
}
