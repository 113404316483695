@mixin createContainerUtilities($utilityClass, $attribute, $unit) {
    @for $i from 1 through 200 {
        .#{$utilityClass}-#{$i} {
            #{$attribute}: #{$i}#{$unit};
        }
    }
}

@mixin createUtilitiesSize($utilityClass, $attribute, $unit) {
    @for $i from 1 through 200 {
        .#{$utilityClass}-#{$i} {
            #{$attribute}: #{$i}#{$unit};
        }
    }
}

@mixin createOpacityUtilities($utilityClass) {
    @for $i from 1 through 10 {
        .#{$utilityClass}-0#{$i} {
            opacity: #{$i * 0.1};
        }
    }
}

@mixin createMarginAxesUtilities($utilityClass, $attribute1, $attribute2, $unit) {
    @for $i from 1 through 200 {
        .#{$utilityClass}-#{$i} {
            #{$attribute1}: #{$i}#{$unit};
            #{$attribute2}: #{$i}#{$unit};
        }
    }
}

@mixin createUtilitiesColor($map, $secondaryKey, $utilityClass, $attribute) {

    @each $primaryKey,
    $val in $map {
        .#{$utilityClass}-#{$primaryKey} {
            #{$attribute}: #{map-get($val, $secondaryKey)};
        }
    }
}

@mixin createUtilitiesWeight($map, $secondaryKey, $utilityClass, $attribute) {

    @each $primaryKey,
    $val in $map {
        .#{$utilityClass}-#{$primaryKey} {
            #{$attribute}: #{map-get($val, $secondaryKey)};
        }
    }
}

@mixin border-radius($border) {
    border-radius: $border;
    -webkit-border-radius: $border;
    -moz-border-radius: $border;
    -ms-border-radius: $border;
    -o-border-radius: $border;
}

@mixin createGridUtilities($utilityClass) {
    @for $i from 1 through 12 {
        .#{$utilityClass}-#{$i} {
            width: calc(#{$i} * 8.3333333333334%);
        }
    }
}