.ds-flex {
    display: flex;
}

.ds-flex-col {
    display: flex;
    flex-direction: column;
}
.ds-flex-row{
    display: flex;
    flex-direction: row;
}

.ds-center {
    justify-content: center;
    align-items: center;
}
.ds-content-center {
    align-content: center;
}

.ds-justify-center {
    justify-content: center;
}

.ds-justify-end {
    justify-content: flex-end;
}

.ds-justify-start {
    justify-content: flex-start;
}

.ds-align-center {
    align-items: center;
}

.ds-align-end {
    align-items: flex-end;
}

.ds-align-start {
    align-items: flex-start;
}

.ds-justify-between {
    justify-content: space-between;
}

.ds-justify-around {
    justify-content: space-around;
}

.ds-flex-wrap {
    flex-wrap: wrap;
}

.ds-align-baseline {
    align-items: baseline;
}
.ds-flex-grow1{
    flex-grow: 1;
}