.ds-container-pricing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 25px;
    margin: auto;
}

.ds-ligne-data {
    display: grid;
    grid-template-columns: auto 95%;
}

.ds-text-break {
    overflow-wrap: break-word;
}

.ds-pricing-col {
    border: 1px solid $neutral100;
    color: $white;
    text-align: left;
}

.ds-primary-pricing-cl {
    box-shadow: 0px 25px 50px -12px $neutral100;
    border-radius: 12px;
    flex: none;
    order: 1;
    flex-grow: 1;
}

.ds-text-pricing {
    text-transform: uppercase;
}

.ds-icon-pricing {
    flex-direction: row;
    gap: 10px;
}
