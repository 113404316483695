$lato: "Lato";
$dark: #000000;
$white: #FFFFFF;
$grayTertiary: #4E4E4E;

$primary10: #E6EAEB;
$primary20: #CDD6D6;
$primary30: #B4C1C2;
$primary40: #9BACAD;
$primary50: #819799;
$primary60: #688385;
$primary70: #4F6E70;
$primary80: #36595C;
$primary90: #1D4547;
$primary100: #043033;
$primary: #043033;

$secondary10: #F0F9FA;
$secondary20: #E0F2F4;
$secondary30: #D1ECEF;
$secondary40: #C1E5EA;
$secondary50: #B2DFE4;
$secondary60: #A3D9DF;
$secondary70: #93D2DA;
$secondary80: #84CCD5;
$secondary90: #74C5CF;
$secondary100: #65BFCA;
$secondary: #65BFCA;

$neutral4: #F7F7F7;
$neutral10: #EBEBEB;
$neutral20: #D6D6D6;
$neutral40: #ADADAD;
$neutral70: #707070;
$neutral100: #333333;

$success4: #F6FDF9;
$success10: #E9F9EF;
$success20: #D3F3DF;
$success40: #A7E8BF;
$success70: #64D68E;
$success100: #22C55E;

$warning4: #FFFBF5;
$warning10: #FEF5E7;
$warning20: #FDECCE;
$warning40: #FBD89D;
$warning70: #F8BB54;
$warning100: #F59E0B;

$error4: #FEF8F8;
$error10: #FDECEC;
$error20: #FCDADA;
$error40: #F9B4B4;
$error70: #F47C7C;
$error100: #EF4444;

$robinblue:#43DCAE;
$robinred:#BA274A;
$robinpeach:#FFC49B;
$robinpapaya:#FFEFD3;
$transparent: transparent;
$greyBackground: rgba(0, 0, 0, .7); 